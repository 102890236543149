<template>
  <section>
    <div class="text-center default-panel-controls">
      <span
        v-if="isDefault"
        class="btn btn-xs"
        :disabled="busy"
        :title="$t('reset')"
        @click.stop.prevent="updateDefault('resetDefault')"
      >
        <span class="text-primary" style="padding:0 10px">
          <i class="fa fa-check-square-o"></i>
          {{ $t("this_panel_is_set_as_default") }}
        </span>
        <span class="btn btn-xs">
          <i class="fa fa-refresh fa-spin" v-if="busy"></i>
          <i class="fa fa-undo" v-else></i>
        </span>
      </span>
      <span
        class="btn btn-xs"
        :disabled="busy"
        @click.stop.prevent="updateDefault('setAsDefault')"
        v-else
      >
        <i class="fa fa-refresh fa-spin" v-if="busy"></i>
        {{ $t("set_as_default") }}
        <i class="fa fa-square-o"></i>
      </span>
      <ToolTip
        :title="$t('hints.device_table_list_standard_panel')"
        position="bottom"
        style="vertical-align: middle;margin-left: 15px;"
      />
    </div>
    <TogglePanel
      :title="$tc('card', 2)"
      persistent="toogle_equipment_search_panel_card"
    >
      <div class="content-panel">
        <div class="form-group form-group-sm" style="padding-top:5px;">
          <div class="input-group">
            <div
              class="input-group-addon btn"
              :class="!availableCards.length ? 'disabled' : ''"
            >
              {{ $tc("card", 1) }}
            </div>
            <select
              class="form-control"
              @change="addCard($event.target.value)"
              :disabled="!availableCards.length"
            >
              <option value="">{{
                $t(availableCards.length ? "select" : "not_available")
              }}</option>
              <option
                v-for="card in availableCards"
                :value="card.name"
                :key="card.name"
                >{{ $tc(card.name, 1) }}</option
              >
            </select>
          </div>
        </div>
        <div v-if="cards && cards.length">
          <label for="">{{ $t("configured_cards") }}</label>
        </div>
        <div v-else class="text-center" style="color:#888">
          <label for="">{{ $t("no_cards_configured") }}</label>
        </div>
        <draggable
          class="list-group"
          v-model="cards"
          :options="{ disabled: hasVisibleCards }"
        >
          <div
            class="list-group-item item group-item"
            v-for="card in cards"
            :key="card.name"
          >
            <div
              class="header clicable"
              :class="isVisible(card.name) ? 'active' : ''"
            >
              <div @click.stop.prevent="collapse(card.name)">
                <i :class="card.config.icon.class" style="min-width:20px"></i>
                {{ $tc(card.name, 1) }}
              </div>
              <div class="toolbar">
                <span
                  class="btn btn-xs"
                  @click.stop.prevent="toggle(card.name)"
                >
                  <i
                    :class="card.enabled ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  ></i>
                </span>
                <span
                  class="btn btn-xs"
                  @click.stop.prevent="delCard(card.name)"
                >
                  <i class="fa fa-trash"></i>
                </span>
                <span
                  class="btn btn-xs"
                  @click.stop.prevent="collapse(card.name)"
                >
                  <i
                    class="glyphicon"
                    :class="
                      isVisible(card.name)
                        ? 'glyphicon-collapse-up'
                        : 'glyphicon-collapse-down'
                    "
                  ></i>
                </span>
              </div>
            </div>
            <div v-if="isVisible(card.name)" class="card-form-container">
              <div>
                <GenericCardForm
                  :entryTemplate="cardEntryTemplate"
                  v-model="card.config"
                  class="card-form"
                />
                <div class="checkbox options">
                  <label :for="`chk-${card.name}`">
                    <input
                      type="checkbox"
                      :id="`chk-${card.name}`"
                      :value="card.config.showFilter"
                      @click="toggleCardFilter(card.name)"
                    />
                    <i class="fa fa-filter"></i>
                    {{ $tc("filter_enabled", 1) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <div v-if="cards && cards.length" style="margin: -10px 2px">
          <ControlStyleProperties
            v-model="statsPanelStyle"
            title="background"
            :togglePanelIcons="{
              collapse: 'fa-caret-square-o-up',
              expand: 'fa-caret-square-o-down',
              before: ''
            }"
          />
        </div>
      </div>
    </TogglePanel>
    <TogglePanel
      :title="$tc('string_search', 1)"
      persistent="toogle_equipment_search_panel_string"
    >
      <div class="content-panel">
        <div class="checkbox">
          <label for="showStringSearch">
            <input
              type="checkbox"
              id="showStringSearch"
              v-model="showStringSearch"
            />
            {{ $tc("enabled", 1) }}
          </label>
        </div>
        <div class="checkbox" v-if="showStringSearch">
          <label for="showNewConnectorButton">
            <input
              type="checkbox"
              id="showNewConnectorButton"
              v-model="showNewConnectorButton"
            />
            {{ $t("show_new_connector_button") }}
          </label>
        </div>
      </div>
    </TogglePanel>
    <TogglePanel
      :title="$tc('banner', 1)"
      v-if="isWhiteLabel"
      persistent="toogle_equipment_search_panel_content"
    >
      <div class="content-panel">
        <div class="checkbox">
          <label for="showBanner">
            <input type="checkbox" id="showBanner" v-model="showBanner" />
            {{ $tc("visible", 1) }}
          </label>
        </div>
        <div v-if="showBanner">
          <ControlStyleProperties
            v-model="bannerStyle"
            :togglePanelIcons="{
              collapse: 'fa-caret-square-o-up',
              expand: 'fa-caret-square-o-down',
              before: ''
            }"
          />
          <ImageSelector v-model="bannerSrc" />
        </div>
      </div>
    </TogglePanel>
  </section>
</template>

<script>
import Panels from "@/assets/dashboard/panels.json";
import ToolTip from "@/components/tooltip.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import draggable from "vuedraggable";
import GenericCardForm from "@/components/control-sidebar/property-editors/generic-card-form.vue";
import ImageSelector from "@/components/synoptic/property-editor/controls/image-selection.vue";
import ContractService from "@/services/contract.js";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";

const defaultPanelOptions = () =>
  Panels.find((panel) => panel.template.template == "EquipmentSearchPanel")
    ?.template?.options || {};

const defaultCard = (queryName) =>
  (defaultPanelOptions()?.cards || []).find(({ name }) => name == queryName) ||
  null;

export { defaultPanelOptions };

const CONTRACT_PANEL_NAME = "EquipmentSearchPanel";

export default {
  name: "DetailFormEquipmentSearch",
  components: {
    draggable,
    ToolTip,
    TogglePanel,
    GenericCardForm,
    ImageSelector,
    ControlStyleProperties
  },
  data: () => ({
    busy: false,
    visibleCards: {}
  }),
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        .screenId;
    },
    screenPath() {
      return (
        (this.$store.getters["dashboard/screen"](this.screenId) || {})?.path ||
        null
      );
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    isWhiteLabel() {
      return this?.contract?.is_white_label || false;
    },
    hasVisibleCards() {
      for (var cardName in this.visibleCards) {
        if (this.visibleCards[cardName]) return true;
      }
      return false;
    },
    cards: {
      set(value) {
        this.$set(this.panel.options.statsPanel, "cards", value);
      },
      get() {
        return this?.panel?.options?.statsPanel?.cards || [];
      }
    },
    availableCards() {
      return (defaultPanelOptions()?.cards || []).filter(
        ({ name }) => !this.cards.some((i) => i.name == name)
      );
    },
    showStringSearch: {
      set(value) {
        this.$set(this.panel.options, "showStringSearch", value);
        if (!value) {
          this.showNewConnectorButton = false;
        }
      },
      get() {
        return this?.panel?.options?.showStringSearch || false;
      }
    },
    showNewConnectorButton: {
      set(value) {
        this.$set(this.panel.options, "showNewConnectorButton", value);
      },
      get() {
        return this?.panel?.options?.showNewConnectorButton || false;
      }
    },
    cardEntryTemplate() {
      let stats = { ...this.$store.getters["equipment/stats"] };
      delete stats["source"];
      delete stats["query"];
      let entry = {
        title: "Dynamically generated based on card type",
        info: "Dynamically generated based on card type",
        body: "Dynamically generated based on card type",
        perc: "Dynamically generated based on card type"
      };
      return {
        ...stats,
        card_defaults: entry
      };
    },
    isDefault() {
      return (
        ((this?.contract?.portal_data?.search_page || {})[CONTRACT_PANEL_NAME]
          ?.path || "") == this.screenPath
      );
    },
    showBanner: {
      set(value) {
        let banner = JSON.parse(
          JSON.stringify(this?.panel?.options?.banner || {})
        );
        banner.enabled = value;
        this.$set(this.panel.options, "banner", banner);
      },
      get() {
        return this?.panel?.options?.banner?.enabled || false;
      }
    },
    bannerSrc: {
      set(value) {
        let banner = JSON.parse(
          JSON.stringify(this?.panel?.options?.banner || {})
        );
        banner.src = value;
        this.$set(this.panel.options, "banner", banner);
      },
      get() {
        return (
          this?.panel?.options?.banner?.src ||
          this?.$root?.config?.company_banner ||
          ""
        );
      }
    },
    bannerStyle: {
      set(value) {
        let banner = JSON.parse(
          JSON.stringify(this?.panel?.options?.banner || {})
        );
        banner.style = value;
        this.$set(this.panel.options, "banner", banner);
      },
      get() {
        return this?.panel?.options?.banner?.style;
      }
    },
    statsPanelStyle: {
      set(value) {
        let statsPanel = JSON.parse(
          JSON.stringify(this?.panel?.options?.statsPanel || {})
        );
        statsPanel.style = value;
        this.$set(this.panel.options, "statsPanel", statsPanel);
      },
      get() {
        return this?.panel?.options?.statsPanel?.style;
      }
    }
    // END
  },
  watch: {
    panelOptions: {
      handler() {
        this.$store.dispatch("dashboard/saveDraftPanel", {
          panel: this.panel,
          screenId: this.screenId
        });
      },
      deep: true
    },
    isDefault() {
      if (this.busy) {
        this.busy = false;
      }
    }
  },
  methods: {
    isVisible(cardName) {
      return cardName in this.visibleCards;
    },
    collapse(cardName) {
      if (cardName in this.visibleCards) {
        this.$delete(this.visibleCards, cardName);
      } else {
        // to allow more than one visible card uncomment line below
        // this.$set(this.visibleCards, cardName, true);
        this.visibleCards = {};
        this.$set(this.visibleCards, cardName, true);
      }
    },
    toggle(cardName) {
      if (cardName) {
        let card = this.cards.find(({ name }) => name == cardName) || null;
        if (card) {
          this.$set(card, "enabled", !card.enabled);
        }
      }
    },
    toggleCardFilter(cardName) {
      if (cardName) {
        let card = this.cards.find(({ name }) => name == cardName) || null;
        if (card) {
          this.$set(card, "showFilter", !card.showFilter);
        }
      }
    },
    onPanelEvent(e) {
      console.log(e);
    },
    addCard(cardName) {
      if (cardName) {
        let card = defaultCard(cardName);
        if (card) {
          let cards = this.cards;
          cards.push(card);
          this.cards = cards;
        }
      }
    },
    delCard(cardName) {
      if (cardName) {
        this.cards = this.cards.filter(({ name }) => name != cardName);
        this.$delete(this.visibleCards, cardName);
      }
    },
    // TODO: Create a base panel form with all functions below
    // BEGIN
    savePortalData(payload) {
      this.busy = true;
      this.srv.updateContract(payload).then((response) => {
        if (response && typeof response == "object" && response.id) {
          this.$store.dispatch("user/configureUserContract");
        } else {
          this.$swal({
            title: self.$t("item_could_not_be_saved"),
            text: self.$t("invalid_data"),
            icon: "error"
          });
        }
      });
    },
    updateDefault(option) {
      if (!this.screenPath || this.busy) return;
      let payload = {
        portal_data: JSON.parse(
          JSON.stringify(this?.contract?.portal_data || {})
        ),
        etag: this?.contract?.etag || "",
        id: this?.contract?.id || ""
      };
      if (!payload.id || !payload.etag) return null;
      if (!payload) return;
      payload.portal_data.search_page = payload.portal_data.search_page || {};
      if (option == "setAsDefault") {
        payload.portal_data.search_page[CONTRACT_PANEL_NAME] = {
          screen_id: this.screenId,
          path: this.screenPath,
          panel_name: this.panel.name
        };
        this.savePortalData(payload);
      } else if (option == "resetDefault") {
        delete payload.portal_data.search_page[CONTRACT_PANEL_NAME];
        this.savePortalData(payload);
      }
    }
    // END
  },
  created() {
    this.srv = new ContractService();
    // this.$root.$on("connector_list_card_form:event", this.onPanelEvent);
  },
  beforeDestroy() {
    // this.$root.$off("connector_list_card_form:event", this.onPanelEvent);
  }
};
</script>

<style scoped>
.content-panel {
  padding: 5px 10px;
  background-color: whitesmoke;
}

.content-panel > .content-style {
  padding: 2px 0 5px 5px;
  margin-top: -14px;
  padding-left: 5px;
  background-color: white;
}

.content-panel > .form-group > label {
  margin-top: 5px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.list-group-item {
  padding: 0;
}

.list-group-item > .header {
  position: relative;
  padding: 4px 5px;
  font-size: 12pt;
}

.list-group-item > .header.active {
  font-weight: 600;
  color: #337ab7;
}

.list-group-item > .header > .toolbar {
  position: absolute;
  right: 0;
  top: 2px;
}

.card-form-container {
  padding-bottom: 40px;
  margin-bottom: 2px;
  background-color: whitesmoke;
  border-bottom: 2px solid #cdcdcd;
}

.card-form-container > div {
  padding: 5px 10px;
}

.card-form-container > div > .card-form {
  background: white;
}

.card-form-container > div > .options {
  padding: 5px;
}

.default-panel-controls {
  padding: 5px 0;
  font-size: 10pt;
  white-space: nowrap;
  border-width: 1px 0;
  border-color: rgb(189, 189, 189);
  border-style: solid;
}
</style>
