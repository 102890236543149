<template>
  <div class="me">
    <!-- BEGIN Connection type filter -->
    <template v-if="filterType == 'connection'">
      <label class="filter-option radio-inline">
        <input type="radio" value="all" v-model="connectionChecked" />
        {{ $tc("all") }}
      </label>
      <label class="filter-option radio-inline">
        <input type="radio" value="connected" v-model="connectionChecked" />
        {{ $tc("connected", 2) }}
      </label>
      <label class="filter-option radio-inline">
        <input type="radio" value="disconnected" v-model="connectionChecked" />
        {{ $tc("disconnected", 2) }}
      </label>
      <select class="filter-option form-control" v-model="connectionChecked">
        <option value="all">{{ $tc("all") }}</option>
        <option value="connected">{{ $tc("connected", 2) }}</option>
        <option value="disconnected">{{ $tc("disconnected", 2) }}</option>
      </select>
      <label
        class="filter-option checkbox-inline"
        v-if="connectionChecked == 'disconnected'"
      >
        <input type="checkbox" v-model="connectionDisabled" />
        {{ $tc("disabled", 2) }}
      </label>
    </template>
    <!-- END Connection type filter -->

    <!-- BEGIN Alarm type filter -->
    <template v-if="filterType == 'alarm'">
      <label class="filter-option checkbox-inline">
        <input type="checkbox" v-model="alarmChecked" />
        <span>{{ $tc("alarmed") }}</span>
      </label>
    </template>
    <!-- END Alarm type filter -->

    <!-- BEGIN processArea type filter -->
    <template v-if="filterType == 'process_area'">
      <div class="select-container">
        <div>
          <i class="fa fa-filter"></i>
        </div>
        <div>
          <select v-model="processAreaChecked">
            <option value=""> {{ $tc("all") }} </option>
            <option
              v-for="(item, ix) in equipmentProcessAreaList"
              v-bind:key="ix"
              v-bind:value="item.id"
            >
              {{ item.title.toUpperCase() }}
            </option>
          </select>
        </div>
      </div>
    </template>
    <!-- END processArea type filter -->
  </div>
</template>

<script>
export default {
  name: "CardFilters",
  props: {
    filterType: {
      type: String,
      required: false,
      default: "connection"
    }
  },
  computed: {
    query: {
      set(value) {
        return this.$store.dispatch("equipment/setQuery", value);
      },
      get() {
        return this.$store.getters["equipment/query"];
      }
    },
    connectionChecked: {
      set(value) {
        let query = JSON.parse(JSON.stringify(this.query));
        query.query_connection.checked = value;
        if (value != "disconnected") {
          query.query_connection.disabled = false;
        }
        this.query = query;
      },
      get() {
        return this.query.query_connection.checked;
      }
    },
    connectionDisabled: {
      set(value) {
        let query = JSON.parse(JSON.stringify(this.query));
        query.query_connection.disabled = value;
        this.query = query;
      },
      get() {
        return this.query.query_connection.disabled;
      }
    },
    alarmChecked: {
      set(value) {
        let query = JSON.parse(JSON.stringify(this.query));
        query.query_alarm = value;
        this.query = query;
      },
      get() {
        return this.query.query_alarm;
      }
    },
    processAreaChecked: {
      set(value) {
        let query = JSON.parse(JSON.stringify(this.query));
        query.query_process_area = query.query_process_area || {};
        query.query_process_area.id = value;
        this.query = query;
      },
      get() {
        return (
          ((this?.equipmentProcessAreaList || []).some(
            ({ id }) =>
              parseInt(this.query?.query_process_area?.id) == parseInt(id)
          ) &&
            this.query?.query_process_area?.id) ||
          ""
        );
      }
    },
    equipmentProcessAreaList() {
      return this.$store.getters["equipment/equipmentProcessAreaList"];
    }
  }
};
</script>

<style scoped>
.me {
  clear: left;
  white-space: nowrap;
  background-color: white;
  color: #5f5f5f;
  opacity: 0.9;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  text-align: center;
}

.filter-option {
  font-weight: 700;
}

.filter-option.form-control {
  display: none;
  flex: 1;
  background: white;
  color: inherit;
  border: none;
  height: 20px;
  padding: 0;
}

.filter-option:hover {
  cursor: pointer;
  color: #5589a7;
}

.filter-option.checkbox-inline {
  margin-left: 10px;
}

.select-container > div {
  display: inline-block;
}

.select-container > div > select {
  margin-left: 5px;
  padding: 0 10px 0 5px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  font-size: 0.9em;
  font-weight: bold;
}

.select-container > div > select:hover {
  cursor: pointer;
  color: #5589a7;
}

.select-container > div > select:focus {
  outline: none;
}

@media (min-width: 1240px) and (max-width: 1324px) {
  .filter-option {
    font-size: 0.9em;
    line-height: 1.7em;
  }
}

@media (min-width: 990px) and (max-width: 1240px) {
  .filter-option.radio-inline {
    display: none;
  }

  .filter-option.form-control {
    display: inline-block;
  }

  .filter-option.checkbox-inline {
    flex: 1;
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .filter-option.radio-inline {
    display: none;
  }

  .filter-option.form-control {
    display: inline-block;
  }

  .filter-option.checkbox-inline {
    flex: 1;
    margin: 0;
  }
}
</style>
