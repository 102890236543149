<template>
  <section>
    <!-- /Search -->
    <EquipmentSearchPanel :source="source" />
    <!-- -->
    <div class="text-center" v-if="state == 'busy'" style="padding:10px">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <div class="result-table" v-else-if="equipmentList && equipmentList.length">
      <EquipmentCardPanel
        @pick="showEquipmentWithId"
        :equipmentList="equipmentList"
        :panel="customPanel"
      />
    </div>

    <!-- -->
  </section>
</template>

<script>
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import EquipmentCardPanel from "@/components/equipment-card-panel.vue";
export default {
  name: "EquipmentSearchCard",
  extends: EquipmentSearchBase,
  components: {
    EquipmentCardPanel
  },
  props: {
    filterCriteria: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected",
        "process_area"
      ],
      customPanel: undefined
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    allEquipmentFields() {
      let self = this;
      return (
        (self.$root.config.equipment_selection &&
          self.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      let self = this;
      return this.allEquipmentFields.filter(function(i) {
        return self.fixedEquipmentProperties.indexOf(i.name) == -1;
      });
    },
    showBanner() {
      let self = this;
      return self.$root.config.equipment_selection &&
        "show_banner" in self.$root.config.equipment_selection
        ? self.$root.config.equipment_selection.show_banner
        : false;
    },
    customConfiguration() {
      return (
        (this?.contract?.portal_data?.search_page || {})[this.$options.name] ||
        null
      );
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || null;
    },
    state() {
      if (
        !this.contract ||
        (this.customConfiguration && this.customPanel === undefined)
      )
        return "busy";
      return "ready";
    }
  },
  watch: {
    customConfiguration: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    },
    screens: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    showField(name) {
      return (
        this.allEquipmentFields.filter(function(i) {
          return i.name == name;
        }).length > 0
      );
    },
    customTemplate() {
      return (
        this.$store.getters["dashboard/template"](
          this.customConfiguration.screen_id
        ) || null
      );
    },
    setCustomPanel(template) {
      if (!this.customPanel) {
        let panel =
          ((template && template?.panels) || []).find(
            ({ name }) => name == this.customConfiguration.panel_name
          ) || null; // important - default null would render the default layout
        this.$set(this, "customPanel", panel);
      }
    },
    async updateCustomView() {
      if (
        this?.customConfiguration &&
        (this.screens || []).some(
          ({ id }) => id == this?.customConfiguration.screen_id
        )
      ) {
        let template = this.customTemplate();
        if (template) {
          this.setCustomPanel(template);
        } else {
          await this.$store
            .dispatch(
              "dashboard/fetchTemplate",
              this.customConfiguration.screen_id
            )
            .then((response) => {
              if (response) {
                this.setCustomPanel(response);
              }
            });
        }
      }
    }
  },
  created() {},
  mounted() {
    this.refreshEnabled = true;
  }
};
</script>

<style scoped>
.tab-search {
  padding-bottom: 200px !important;
}
.equipment-item {
}
.equipment-item:hover {
  cursor: pointer;
}
.equipment-item .info-box {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.equipment-item .info-box:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.equipment-enabled {
  opacity: 1;
}

.result-table {
  min-height: 400px;
}
</style>
