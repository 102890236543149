<template>
  <div>
    <div v-if="title">
      <label style="font-size: 12pt; padding: 5px 2px">{{ $t(title) }}</label>
    </div>
    <div class="content-panel">
      <ControlStyleProperties
        v-model="cardStyle"
        :togglePanelIcons="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down',
          before: ''
        }"
        @changed="setOpenBlock($event, 'card_style')"
      >
        <template v-slot:title>
          <span
            :class="'card_style' == openedBlock ? 'text-primary text-bold' : ''"
          >
            {{ $tc("appearance", 1) }}
          </span>
        </template>
      </ControlStyleProperties>
      <TogglePanel
        :title="$tc('icon', 1)"
        style="margin-top: 10px"
        @changed="setOpenBlock($event, 'status_icon')"
        :collapsed="'status_icon' != openedBlock"
      >
        <template v-slot:title>
          <span
            :class="
              'status_icon' == openedBlock ? 'text-primary text-bold' : ''
            "
          >
            {{ $tc("icon", 1) }}
          </span>
        </template>
        <div class="content-panel content-inner">
          <div class="row">
            <div class="col-xs-3">
              <div>
                {{ $tc("image", 1) }}
              </div>
              <div class="btn" @click="showIconLibrary = !showIconLibrary">
                <i :class="iconClass"></i>
              </div>
            </div>
            <div class="col-xs-9">
              <div>
                {{ $tc("color", 2) }}
                <div>
                  <ColorPicker
                    icon="fa fa-paint-brush"
                    style="white-space: normal; display: inline-block"
                    v-model="iconFGColor"
                  />
                  <ColorPicker
                    icon="background"
                    :disabled="iconDynamicColorEnabled"
                    style="white-space: normal; display: inline-block"
                    v-model="iconBGColor"
                  />
                </div>
              </div>
            </div>
            <div style="position: relative">
              <div style="position: absolute; right: 0; top: 150px">
                <IconLibrary
                  v-model="iconClass"
                  :panelOpen="showIconLibrary"
                  :buttonAttrs="null"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div class="checkbox">
                <label for="dyncolor">
                  <input
                    type="checkbox"
                    id="dyncolor"
                    v-model="iconDynamicColorEnabled"
                  />
                  {{ $t("dynamic_background_color") }}
                </label>
              </div>
              <div
                class="col-xs-12 form-group form-group-sm"
                v-if="iconDynamicColorEnabled"
              >
                <select
                  class="form-control legend-control"
                  v-model="iconDynamicColor"
                >
                  <option
                    :value="item.id"
                    v-for="item in dynamicColorList"
                    :key="item.id"
                  >
                    {{ iconText(item.title) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </TogglePanel>
      <TogglePanel
        title="title"
        @changed="setOpenBlock($event, 'title')"
        :collapsed="'title' != openedBlock"
      >
        <template v-slot:title>
          <span :class="'title' == openedBlock ? 'text-primary text-bold' : ''">
            {{ $tc("title", 1) }}
          </span>
        </template>
        <div class="content-panel content-inner">
          <div class="form-group form-group-sm">
            <label>{{ $t("expression") }}</label>
            <JSONPathPicker
              entryType="item"
              :append="true"
              :curlyBracked="true"
              :entry="entry"
              v-model="titleExpression"
            />
          </div>
          <ControlStyleProperties v-model="titleStyle" class="content-style" />
        </div>
      </TogglePanel>
      <TogglePanel
        title="content"
        @changed="setOpenBlock($event, 'content')"
        :collapsed="'content' != openedBlock"
      >
        <template v-slot:title>
          <span
            :class="'content' == openedBlock ? 'text-primary text-bold' : ''"
          >
            {{ $tc("content", 1) }}
          </span>
        </template>
        <div class="content-panel content-inner">
          <div class="form-group form-group-sm">
            <label>{{ $t("expression") }}</label>
            <JSONPathPicker
              entryType="item"
              :append="true"
              :curlyBracked="true"
              :entry="entry"
              v-model="bodyExpression"
            />
          </div>
          <ControlStyleProperties v-model="bodyStyle" class="content-style" />
        </div>
      </TogglePanel>
      <TogglePanel
        title="footer"
        @changed="setOpenBlock($event, 'footer')"
        :collapsed="'footer' != openedBlock"
      >
        <template v-slot:title>
          <span
            :class="'footer' == openedBlock ? 'text-primary text-bold' : ''"
          >
            {{ $tc("footer", 1) }}
          </span>
        </template>
        <div class="content-panel content-inner">
          <div class="form-group form-group-sm">
            <label>{{ $t("expression") }}</label>
            <JSONPathPicker
              entryType="item"
              :append="true"
              :curlyBracked="true"
              :entry="entry"
              v-model="footerExpression"
            />
            <ControlStyleProperties
              v-model="footerStyle"
              class="content-style"
            />
          </div>
        </div>
      </TogglePanel>
      <TogglePanel
        title="progress"
        v-if="card && card.progress"
        @changed="setOpenBlock($event, 'progress')"
        :collapsed="'progress' != openedBlock"
      >
        <template v-slot:title>
          <span
            :class="'progress' == openedBlock ? 'text-primary text-bold' : ''"
          >
            {{ $tc("progress", 1) }}
          </span>
        </template>
        <div class="content-panel content-inner">
          <div class="form-group form-group-sm">
            <label>{{ $t("expression") }}</label>
            <JSONPathPicker
              entryType="item"
              :append="true"
              :curlyBracked="true"
              :entry="entry"
              v-model="progressExpression"
            />
          </div>
          <ControlStyleProperties
            v-model="progressStyle"
            class="content-style"
          />
        </div>
      </TogglePanel>
      <TogglePanel
        title="display"
        v-if="card && card.display"
        @changed="setOpenBlock($event, 'display')"
        :collapsed="'display' != openedBlock"
      >
        <template v-slot:title>
          <span
            :class="'display' == openedBlock ? 'text-primary text-bold' : ''"
          >
            {{ $tc("display", 1) }}
          </span>
        </template>
        <div class="content-panel content-inner">
          <div class="form-group form-group-sm">
            <label>{{ $t("expression") }}</label>
            <JSONPathPicker
              entryType="item"
              :append="true"
              :curlyBracked="true"
              :entry="entry"
              v-model="displayExpression"
            />
          </div>
          <ControlStyleProperties
            v-model="displayStyle"
            class="content-style"
          />
        </div>
      </TogglePanel>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import StatusIcons from "@/assets/dashboard/status_icons.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import IconLibrary from "@/components/editor/icon-library.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import ColorPicker from "@/components/editor/color-picker";

export default {
  name: "GenericCardForm",
  components: {
    TogglePanel,
    JSONPathPicker,
    IconLibrary,
    ControlStyleProperties,
    ColorPicker
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => { }
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    entryTemplate: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  data() {
    return {
      card: null,
      showIconLibrary: false,
      openedBlock: ""
    };
  },
  computed: {
    cardStyle: {
      set(value) {
        let card = this?.card || null;
        if (card) {
          this.$set(card, "style", value);
        }
      },
      get() {
        return this?.card?.style || {};
      }
    },
    iconClass: {
      set(value) {
        this.showIconLibrary = false;
        let icon = this?.card?.icon || null;
        if (icon) {
          this.$set(icon, "class", value);
        }
      },
      get() {
        return this?.card?.icon?.class || "";
      }
    },
    iconStyle: {
      set(value) {
        let icon = this?.card?.icon || null;
        if (icon) {
          this.$set(icon, "style", value);
        }
      },
      get() {
        return this?.card?.icon?.style || {};
      }
    },
    iconFGColor: {
      set(value) {
        let style = JSON.parse(JSON.stringify(this.iconStyle || {}));
        this.$set(style, "color", value);
        this.iconStyle = style;
      },
      get() {
        return this?.iconStyle?.color || "";
      }
    },
    iconBGColor: {
      set(value) {
        let style = JSON.parse(JSON.stringify(this.iconStyle || {}));
        this.$set(style, "background-color", value);
        this.iconStyle = style;
      },
      get() {
        return (this?.iconStyle || {})["background-color"] || "";
      }
    },
    iconDynamicColor: {
      set(value) {
        let icon = this?.card?.icon || null;
        if (icon) {
          this.$set(icon, "dynamicColor", value);
        }
      },
      get() {
        return this?.card?.icon?.dynamicColor || "";
      }
    },
    dynamicColorList() {
      return [
        { id: "connectorConnection", title: "connector_connection" },
        { id: "deviceConnection", title: "device_connection" },
        { id: "connectorAlarmed", title: "connector_alarmed" },
        { id: "deviceAlarmed", title: "device_alarmed" }
      ];
    },
    iconDynamicColorEnabled: {
      set(value) {
        if (value) {
          this.iconDynamicColor = this.dynamicColorList[0].id;
        } else {
          this.iconDynamicColor = "";
        }
      },
      get() {
        return (
          this.dynamicColorList
            .map(({ id }) => id)
            .indexOf(this.iconDynamicColor) >= 0
        );
      }
    },
    titleExpression: {
      set(value) {
        this.showIconLibrary = false;
        this.expression("title", value);
      },
      get() {
        return this.expression("title");
      }
    },
    titleStyle: {
      set(value) {
        this.style("title", value);
      },
      get() {
        return this.style("title");
      }
    },
    bodyExpression: {
      set(value) {
        this.expression("body", value);
      },
      get() {
        return this.expression("body");
      }
    },
    bodyStyle: {
      set(value) {
        this.style("body", value);
      },
      get() {
        return this.style("body");
      }
    },
    footerExpression: {
      set(value) {
        this.expression("footer", value);
      },
      get() {
        return this.expression("footer");
      }
    },
    footerStyle: {
      set(value) {
        this.style("footer", value);
      },
      get() {
        return this.style("footer");
      }
    },
    progressExpression: {
      set(value) {
        this.expression("progress", value);
      },
      get() {
        return this.expression("progress");
      }
    },
    progressStyle: {
      set(value) {
        this.style("progress", value);
      },
      get() {
        return this.style("progress");
      }
    },
    displayExpression: {
      set(value) {
        this.expression("display", value);
      },
      get() {
        return this.expression("display");
      }
    },
    displayStyle: {
      set(value) {
        this.style("display", value);
      },
      get() {
        return this.style("display");
      }
    },
    connectorTemplate() {
      let icons = {};
      // only the ones with dynamic state
      StatusIcons.filter((icon) => {
        return icon.stateList.some((s) => "value" in s);
      }).forEach((icon) => {
        icons[icon.name] = {
          image: icon.stateList[0].class,
          text: icon.title
        };
      });
      let connector = this.$store.getters["dashboard/dashboardEquipment"] || {};
      return {
        ...SelectableObjects["connector"],
        user_data: {
          extended_properties: {
            ...(connector?.user_data?.extended_properties || {})
          }
        },
        icons: icons
      };
    },
    entry() {
      return this.entryTemplate || this.connectorTemplate;
    }
  },
  watch: {
    value: {
      handler(n) {
        let nEntry = JSON.parse(JSON.stringify(n));
        let oEntry = JSON.parse(JSON.stringify(this.card));
        if (isEqual(nEntry, oEntry)) return;
        this.$set(this, "card", nEntry);
      },
      immediate: true,
      deep: true
    },
    card: {
      handler(n) {
        let nEntry = JSON.parse(JSON.stringify(n));
        let oEntry = JSON.parse(JSON.stringify(this.value));
        if (isEqual(nEntry, oEntry)) return;
        this.$emit("input", nEntry);
      },
      deep: true
    }
  },
  methods: {
    expression(attr, value) {
      // expression get/setter
      let obj = this?.card || null;
      if (obj && attr in obj) {
        if (value !== undefined) {
          this.$set(obj[attr], "expression", value);
        }
        return obj[attr]?.expression || "";
      }
      return "";
    },
    style(attr, value) {
      // expression get/setter
      let obj = this?.card || null;
      if (obj && attr in obj) {
        if (value) {
          this.$set(obj[attr], "style", value);
        }
        return obj[attr]?.style || {};
      }
      return {};
    },
    iconText(title) {
      let v = (title + "_").split("_");
      return this.$utils.proper(`${this.$tc(v[0], 1)} ${this.$tc(v[1], 1)}`);
    },
    setOpenBlock(isCollapsed, name) {
      this.openedBlock = !isCollapsed ? name : "";
    }
  },
  mounted() { }
};
</script>

<style scoped>
.content-panel {
  padding: 0 2px;
  /* background-color: whitesmoke; */
}

.content-panel > .content-style {
  padding: 2px 0 5px 5px;
  margin-top: -14px;
  padding-left: 5px;
  background-color: white;
}

.content-inner {
  padding: 0 0 10px 15px;
}
.content-panel > .form-group > label {
  margin-top: 5px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.legend-control {
  background-color: transparent;
  z-index: 0;
  padding: 0 3px;
}

.legend-control:focus {
  outline-width: 0;
  border-color: #d2d6de;
}

.legend-label {
  position: relative;
}

.legend-label > div {
  position: absolute;
  top: -8px;
  left: 10px;
  font-size: 10pt;
  background-color: white;
  z-index: 1;
}
</style>
