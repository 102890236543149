<template>
  <div>
    <div
      class="form-group form-group-sm"
      :class="className"
      v-bind="$attrs"
      data-testid="form-group"
    >
      <slot name="label">
        <label
          v-bind="labelAttrs"
          data-testid="src-label"
          :title="
            size ? size.width + 'x' + size.height + ' (' + size.ratio + ')' : ''
          "
          >{{ $t("image") }}
          <ToolTip v-if="tooltip" :title="$t(tooltip)" />
        </label>
      </slot>
      <div class="input-group input-group-sm">
        <input
          type="text"
          class="form-control input-url"
          v-model="src"
          @input="setExternalImage"
          @focus="floatPanel.open = false"
          v-bind="inputAttrs"
          data-testid="src"
          autocomplete="off"
          ref="src"
          :title="
            size ? size.width + 'x' + size.height + ' (' + size.ratio + ')' : ''
          "
        />
        <div class="input-group-btn">
          <button
            class="btn btn-default"
            @click.stop.prevent="onReset"
            v-if="src"
            :title="$t('remove')"
            data-testid="reset"
          >
            <span class="fa fa-close"></span>
          </button>
          <button
            class="btn btn-default"
            :title="$t('synoptic.select_from_library')"
            @click="toggleLibrary"
            data-testid="toggle-library"
          >
            <span class="glyphicon glyphicon-book"></span>
          </button>
        </div>
      </div>
    </div>
    <portal to="modal">
      <FloatPanel
        class="float-panel"
        :draggable="true"
        :handleSelf="true"
        :open="floatPanel.open"
        :defaultPosition="floatPanel.rect"
        @update:open="!$event && resetPanelState()"
        @dragstart="floatPanel.dragging = true"
        @dragend="onDragEnd(floatPanel, $event)"
      >
        <div class="header no-select">
          <template v-if="mode == 'select'">
            {{ $t("library") }}
          </template>
          <template v-if="mode == 'image'">
            {{
              isUpdate
                ? `${$t("edit")} ${$t("image").toLowerCase()}`
                : `${$t("upload")} ${$t("image").toLowerCase()}`
            }}
          </template>
          <template v-if="mode == 'library'">
            {{
              isUpdate
                ? `${$t("edit")} ${$t("library").toLowerCase()}`
                : `${$t("create")} ${$t("library").toLowerCase()}`
            }}
          </template>
        </div>
        <div class="popup">
          <div class="popup-body">
            <template v-if="mode == 'select'">
              <div class="libraries handle">
                <div class="row">
                  <div class="form-group handle col-xs-7">
                    <div class="input-group input-group-sm">
                      <select
                        name="libraries"
                        id="img-libraries"
                        class="form-control"
                        v-model="filterImagesBy"
                        data-testid="libraries"
                      >
                        <option :value="0" data-testid="all-libraries">
                          {{ $tc("all", 2) }}
                        </option>
                        <option
                          v-for="imgLibrary in imagesLibraries"
                          :value="imgLibrary.id"
                          :key="imgLibrary.id"
                          :title="imgLibrary.description"
                          data-testid="library"
                        >
                          {{ imgLibrary.name }}
                        </option>
                        <option
                          class="text-danger"
                          :value="-1"
                          v-if="noImagesLibraries && !imagesLibraries.length"
                        >
                          {{ $t("failed_to_load_libraries") }}
                        </option>
                      </select>
                      <div class="input-group-btn">
                        <button
                          v-if="
                            isSelectedImageLibWritable && filterImagesBy != 0
                          "
                          class="btn btn-default"
                          @click="editLibrary"
                          :title="`${$t('edit')} ${$t(
                            'library'
                          ).toLowerCase()}`"
                          style="min-width: 40px"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button
                          class="btn btn-default"
                          @click="createLibrary"
                          :title="`${$t('create')} ${$t('library')}`"
                          style="min-width: 40px"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group handle col-xs-5">
                    <div class="input-group input-group-sm">
                      <input
                        type="search"
                        class="form-control"
                        v-model="searchTerm"
                        :placeholder="$t('search')"
                        ref="searchField"
                        data-testid="search-field"
                      />
                      <div class="input-group-btn">
                        <button
                          class="btn btn-default"
                          @click="searchTerm = ''"
                          data-testid="search-clear"
                          style="min-width: 40px"
                        >
                          <span class="fa fa-remove"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="images box"
                id="images-container"
                v-if="imagesByRows.length"
                :style="loading ? 'overflow-y: hidden;' : ''"
                @click.self="clearSelected"
                ref="imagesContainer"
                data-testid="images-container"
              >
                <div
                  class="row"
                  v-for="(row, index) in imagesByRows"
                  :key="index"
                  @click.self="clearSelected"
                >
                  <div
                    class="col-xs-6"
                    v-for="image in row"
                    :key="image.id"
                    @click.self="clearSelected"
                    :id="`anc${image.id}`"
                  >
                    <button
                      class="thumbnail"
                      :class="{
                        selected: selectedImage == image,
                        'image-loaded': isLoadedImage(image.path)
                      }"
                      @click="selectedImage = image"
                      @dblclick="selectImage(image)"
                      data-testid="thumbnail"
                      v-lazyload="{
                        rootElement: () => $refs.imagesContainer,
                        loadedClass: 'image-loaded',
                        onLoad: () => setImageLoaded(image.path)
                      }"
                      :title="`${image.description}${
                        loadedImages[image.path]
                          ? '<br>' + loadedImages[image.path].desc
                          : ''
                      }`"
                    >
                      <button
                        class="btn btn-info download"
                        v-show="selectedImage == image"
                        :title="`${$t('download')} ${$t(
                          'image'
                        ).toLowerCase()}`"
                        @click="downloadImage(image)"
                        data-testid="download"
                      >
                        <span class="fa fa-download"></span>
                      </button>
                      <button
                        class="btn btn-primary edit"
                        v-show="
                          selectedImage == image && isSelectedImageLibWritable
                        "
                        :title="`${$t('edit')} ${$t('image').toLowerCase()}`"
                        @click="editImage(image)"
                        data-testid="edit"
                      >
                        <span class="fa fa-pencil"></span>
                      </button>
                      <button
                        class="btn btn-danger delete"
                        v-show="
                          selectedImage == image && isSelectedImageLibWritable
                        "
                        :title="`${$t('remove')} ${$t('image').toLowerCase()}`"
                        @click="removeImage(image)"
                        data-testid="remove"
                      >
                        <span class="glyphicon glyphicon-trash"></span>
                      </button>
                      <!-- use data-src instead of src to be lazy loaded -->
                      <img
                        :data-src="`${image.path}?_=${image.etag}`"
                        :alt="image.name"
                        data-testid="image"
                        ref="images"
                      />
                      <Spin class="spinner" />
                      <span>{{ image.name }}</span>
                    </button>
                  </div>
                </div>
                <Spin v-if="loading" />
              </div>
              <div class="images box box-danger" v-else-if="noImages">
                <span
                  class="center-block text-danger"
                  style="width: max-content"
                  >{{ $t("failed_to_load_images") }}</span
                >
              </div>
              <div class="images box box-default" v-else>
                <span class="center-block" style="width: max-content">{{
                  $t("no_images")
                }}</span>
              </div>
              <div class="action-buttons">
                <button
                  v-if="canUpload"
                  class="btn btn-primary btn-sm"
                  :title="$t('upload')"
                  @click="$refs.imageFile.click()"
                >
                  <span class="fa fa-upload"></span>
                  <input
                    type="file"
                    name="image"
                    id="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="uploadImage"
                    data-testid="img-file"
                  />
                </button>
                <button
                  class="btn btn-default btn-sm"
                  @click="floatPanel.open = false"
                  data-testid="cancel"
                >
                  {{ $t("cancel") }}
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  :disabled="!selectedImage"
                  @click="selectImage()"
                  data-testid="select"
                >
                  {{ $t("select") }}
                </button>
              </div>
            </template>
            <template v-else-if="mode == 'image'">
              <form
                class="libraries handle"
                @submit.prevent="saveImage(isUpdate)"
                data-testid="save-image-form"
              >
                <div class="form-group">
                  <label for="img-libraries">{{ $t("library") }}</label>
                  <select
                    name="libraries"
                    id="img-libraries"
                    class="form-control input-sm"
                    v-model="imagePayload.images_library_id"
                    required
                    ref="imgLibrary"
                    data-testid="libraries"
                    :disabled="errorList.length > 0"
                  >
                    <template v-for="imgLibrary in imagesLibraries">
                      <option
                        v-if="!imgLibrary.public"
                        :value="imgLibrary.id"
                        :key="imgLibrary.id"
                        :title="imgLibrary.description"
                        data-testid="library"
                      >
                        {{ imgLibrary.name }}
                      </option>
                    </template>
                    <option
                      class="text-danger"
                      :value="-1"
                      v-if="noImagesLibraries && !imagesLibraries.length"
                    >
                      {{ $t("failed_to_load_libraries") }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="img-name">{{ $t("description") }}</label>
                  <textarea
                    class="form-control"
                    id="img-description"
                    v-model="imagePayload.description"
                    rows="2"
                    ref="imgDescription"
                    data-testid="description"
                    :disabled="errorList.length > 0"
                  ></textarea>
                </div>
                <div
                  class="text-center"
                  style="
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    margin: 20px 0 20px 0;
                    padding: 20px;
                  "
                >
                  <span v-if="errorList.length" class="text-center">
                    <i
                      class="fa fa-exclamation-triangle text-danger clicable"
                      :title="`${$t('invalid_image')}\n${errorList.join('\n')}`"
                    ></i>
                    {{ `${errorList.join("\n")}` }}
                  </span>
                  <img
                    v-else
                    class="img-responsive"
                    :src="uploadingImage"
                    :alt="$t('image')"
                    data-testid="image-preview"
                    style="display: inline-block; max-width: 40%"
                  />
                </div>
                <div>
                  <button
                    class="btn pull-left"
                    @click.stop.prevent="
                      resetPanelState();
                      toggleLibrary();
                    "
                    style="margin-right: 10px"
                  >
                    {{ $t("cancel") }}
                  </button>
                  <button
                    v-if="!errorList.length > 0"
                    class="btn btn-success pull-right"
                    type="submit"
                    :disabled="loading"
                    data-testid="save"
                    style="margin-right: 10px"
                  >
                    {{ loading ? $t("saving") + "..." : $t("save") }}
                  </button>
                </div>
              </form>
            </template>
            <template v-else-if="mode == 'library'">
              <form
                class="libraries handle"
                @submit.prevent="saveLibrary(isUpdate)"
                data-testid="save-library-form"
              >
                <div class="form-group">
                  <label for="img-name">{{ $t("name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="library-name"
                    v-model="libraryPayload.name"
                    required
                    data-testid="name"
                  />
                </div>
                <div class="form-group">
                  <label for="img-name">{{ $t("description") }}</label>
                  <textarea
                    class="form-control"
                    id="library-description"
                    v-model="libraryPayload.description"
                    rows="2"
                    data-testid="description"
                  ></textarea>
                </div>
                <div>
                  <button
                    class="btn pull-left"
                    @click.stop.prevent="
                      resetPanelState();
                      toggleLibrary();
                    "
                    style="margin-right: 10px"
                  >
                    {{ $t("cancel") }}
                  </button>
                  <button
                    v-if="isUpdate"
                    class="btn btn-danger pull-left"
                    type="button"
                    :disabled="loading"
                    @click="removeLibrary"
                    data-testid="remove"
                  >
                    {{
                      loading && removing
                        ? $t("removing") + "..."
                        : $t("remove")
                    }}
                  </button>
                  <button
                    class="btn btn-success pull-right"
                    type="submit"
                    :disabled="loading"
                    data-testid="save"
                  >
                    {{
                      loading && !removing ? $t("saving") + "..." : $t("save")
                    }}
                  </button>
                </div>
              </form>
            </template>
          </div>
        </div>
      </FloatPanel>
    </portal>
    <img
      src=""
      style="display: none"
      ref="externalImage"
      id="externalImage"
      @load="externalImageLoaded"
      data-testid="external-image"
    />
  </div>
</template>

<script>
import MixinAlert from "@/project/mixin-alert";
import LazyLoadDirective from "@/directives/lazyload";

import Spin from "@/components/spin";
import FloatPanel from "@/components/editor/float-panel";
import ToolTip from "@/components/tooltip.vue";
const _fpsize = [600, 500];
let _fp = {
  w: _fpsize[0],
  h: _fpsize[1],
  x: parseInt((window.innerWidth - _fpsize[0]) / 2),
  y: parseInt((window.innerHeight - _fpsize[1]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);
export default {
  name: "ImageSelection",
  mixins: [MixinAlert],
  directives: { lazyload: LazyLoadDirective },
  components: { Spin, FloatPanel, ToolTip },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    labelAttrs: {
      type: Object,
      required: false,
      default: () => ({ for: "editor-src" })
    },
    inputAttrs: {
      type: Object,
      required: false,
      default() {
        return { id: "editor-src", placeholder: this.$t("url") };
      }
    },
    className: {
      type: String,
      required: false,
      default: ""
    },
    tooltip: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      src: "",
      currentSize: null,
      selectedImage: null,
      noImages: false,
      noImagesLibraries: false,
      filterImagesBy: 0,
      loading: false,
      removing: false,
      // "select" = Select from library
      // "image" = Upload/update image
      // "library" = Create/update library
      mode: "",
      isUpdate: false, // true = use patch instead of post
      actionMap: {
        // map action labeling to isUpdate value
        create: false,
        update: true
      },
      imagePayload: {
        description: "",
        images_library_id: null,
        path: null
      },
      libraryPayload: {
        name: "",
        description: "",
        contract_id: null,
        public: false
      },
      uploadingImage: "",
      searchTerm: "",
      searchEnabled: false,
      defaultPanelPosition: null,
      parentOffset: null,
      loadedImages: {},
      errorList: [],
      floatPanel: {
        rect: {
          top: `${_fp.y}px`,
          left: `${_fp.x}px`
        },
        open: false,
        dragging: false
      }
    };
  },
  computed: {
    canUpload() {
      let lib = (this.imagesLibraries || []).find(
        (i) => i.id == this.filterImagesBy
      );
      if (lib) {
        if (!lib.public) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    isSelectedImageLibWritable() {
      // set lib as selected library
      // or selected image's library
      let lib =
        this.selectedImageLib ??
        this.imagesLibraries.find(
          ({ id }) => id == this.selectedImage?.images_library_id
        );
      if (lib) {
        if (!lib.public) {
          return true;
        }
        return false;
      }
      return true;
    },
    selectedImageLib() {
      return (this.imagesLibraries || []).find(
        (i) => i.id == this.filterImagesBy
      );
    },
    imagesByRows() {
      let rows = [],
        rowIndex = 0;
      this.filteredImages?.forEach((img, index) =>
        index % 2 == 0 ? (rows[rowIndex] = [img]) : rows[rowIndex++].push(img)
      );
      return rows;
    },
    filteredImages() {
      let list = this.images;
      if (this.filterImagesBy) {
        list = list.filter(
          ({ images_library_id }) => images_library_id == this.filterImagesBy
        );
      }
      if (this.searchTerm) {
        list = list.filter((image) =>
          this.$utils.queryStrAtr(this.searchTerm, image, "name,description")
        );
      }
      return list;
    },
    size() {
      return this.loadedImages[this.src] || this.currentSize;
    },
    etag() {
      return (this?.selectedImage?.etag || "").replace(/\"/g, "");
    },
    imagesLibraries() {
      return this.$store.getters["synoptic/imagesLibraries"];
    },
    images() {
      return this.$store.getters["synoptic/images"];
    },
    uploadingImageLibId() {
      return this?.imagePayload?.images_library_id ?? "";
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val != undefined) {
          this.src = val;
        }
      }
    },
    filteredImages(images) {
      if (!images.find(({ id }) => id == this.selectedImage?.id))
        this.clearSelected();
    },
    searchEnabled(val) {
      // increase/decrease bottom position
      // accordingly to search state
      // if (val) {
      //   this.defaultPanelPosition.bottom =
      //     parseInt(this.defaultPanelPosition.bottom) - 30 + "px";
      // } else {
      //   this.defaultPanelPosition.bottom =
      //     parseInt(this.defaultPanelPosition.bottom) + 30 + "px";
      // }
    },
    // mode changing can alter the panel height,
    // so checkPosition is called to check the current position
    mode(val) {
      // this.$nextTick(() => this.checkPosition(this.defaultPanelPosition));
    },
    uploadingImageLibId(n) {
      this.isUpdate =
        (n &&
          (this.images || []).some(
            ({ name, images_library_id }) =>
              parseInt(images_library_id) === parseInt(n) &&
              name === this?.imagePayload?.path?.name
          )) ||
        false;
    }
  },
  methods: {
    setCurrentImageSize() {
      // clue that guides user to proper adjust size aspect ratio
      // todo: uncomment it after cors header be set
      this.$set(this, "currentSize", null);
      if (this.src) {
        try {
          let self = this;
          let img = new Image();
          img.onload = function() {
            self.$set(self, "currentSize", {
              width: this.naturalWidth,
              height: this.naturalHeight,
              ratio: (
                (img.naturalHeight || 1) / (img.naturalWidth || 1)
              ).toFixed(1)
            });
          };
          img.src = this.src;
        } catch (e) {
          this.$set(this, "currentSize", { width: "", height: "", ratio: "" });
        }
      }
    },
    externalImageLoaded() {
      if (this.$refs.externalImage.width && this.$refs.externalImage.height) {
        this.$emit("size", {
          width: this.$refs.externalImage.width,
          height: this.$refs.externalImage.height,
          src: this.src
        });
      }
      this.$emit("input", this.src);
    },
    setExternalImage() {
      if (this.src) {
        this.$refs.externalImage.src = this.src;
      } else {
        this.$emit("input", this.src);
      }
    },
    reset() {
      this.src = "";
      this.$emit("size", null);
      this.$emit("input", this.src);
    },
    onReset() {
      this.reset();
      this.$refs.src.focus();
    },
    resetPanelState() {
      this.clearSelected();
      // let mode empty to prevent unnecessary rendering
      this.floatPanel.open = false;
      this.mode = "";
      this.searchTerm = "";
      this.searchEnabled = false;
    },
    setupPanelPosition() {
      // if (!this.parentOffset) {
      //   // set minimum offset top based on occupied space
      //   this.calculateParentOffset();
      //   //this.defaultPanelPosition.bottom =
      //   //parseInt(this.defaultPanelPosition.bottom) - this.parentOffset + "px";
      //   this.defaultPanelPosition.bottom = `-${this.$refs.floatPanel.$el.getBoundingClientRect()
      //     .height - 50}px`;
      // }
    },
    checkPosition(position) {
      // let rect = this.$refs.floatPanel.$el.getBoundingClientRect();
      // // decrease bottom position if it's behind
      // // the navbar space (considering current scroll)
      // if (rect.top < 50 - window.scrollY) {
      //   this.defaultPanelPosition.bottom =
      //     parseInt(position.bottom) - (50 - window.scrollY - rect.top) + "px";
      // }
    },
    setPanel({ action, resource }) {
      this.isUpdate = this.actionMap[action];
      this.mode = resource;
    },
    toggleLibrary() {
      if (this.mode != "select") {
        this.mode = "select";
        this.clearSelected();
        this.floatPanel.open = true;
      } else this.floatPanel.open = !this.floatPanel.open;
      // set initial panel position
      // if (!this.parentOffset) {
      //   this.defaultPanelPosition = {
      //     bottom: window.getComputedStyle(this.$el).height,
      //     left: "20px"
      //   };
      // }
    },
    toggleSearch() {
      this.searchEnabled = !this.searchEnabled;
      if (this.searchEnabled) {
        this.$refs.searchField.focus();
      }
    },
    selectImage(image) {
      if (image) this.selectedImage = image;
      if (this.selectedImage) {
        this.src = this.selectedImage.path ?? "";
        this.floatPanel.open = false;
        this.$emit("size", { ...this.size, ...{ src: this.src } });
        let src = `${this.src}?_=${this.etag ?? new Date().getTime()}`;
        let o = (this.value || "").split("?")[0];
        let n = (src || "").split("?")[0];
        if (o && n && o === n) {
          this.$store.dispatch("synoptic/replaceImagePath", {
            oldVal: this.value,
            newVal: src
          });
        }
        this.$emit("input", src);
      }
    },
    clearSelected() {
      this.selectedImage = null;
    },
    uploadImage(e) {
      this.$set(this, "errorList", []);
      let file = e.target.files[0];
      // image preview
      let reader = new FileReader();
      reader.onload = (e) => (this.uploadingImage = e.target.result);
      reader.readAsDataURL(file);
      if (file.size && file.size / 1048576 > 1) {
        this.$set(this, "errorList", [`- ${this.$t("size")} > 1MB`]);
      }
      // set imagePayload path
      this.imagePayload = {
        description: "",
        images_library_id: null,
        path: file
      };
      // show panel in image upload mode
      this.setPanel({ action: "create", resource: "image" });
      this.$nextTick(() => {
        // pre-select library if already filtered
        if (this.filterImagesBy) {
          this.imagePayload.images_library_id = this.filterImagesBy;
          // this.$refs.imgDescription.focus();
        } else {
          // this.$refs.imgLibrary.focus();
        }
      });
    },
    async downloadImage(image) {
      try {
        let { body: imgBlob } = await this.$http.get(
          image.path + "?" + Date.now(),
          {
            responseType: "blob"
          }
        );
        let url = URL.createObjectURL(imgBlob);
        let link = document.createElement("a");
        link.href = url;
        link.download = image.name;
        link.click();
        link.remove();
      } catch (e) {
        console.log("Download failed:", e);
        this.$toasted.show(this.$t("download_failed"), {
          type: "error",
          position: "bottom-right",
          iconPack: "fontawesome",
          icon: "warning",
          duration: 5000
        });
      }
    },
    editImage(image) {
      // set imagePayload
      this.imagePayload = {
        id: image.id,
        etag: image.etag,
        description: image.description,
        images_library_id: image.images_library_id
      };
      this.uploadingImage = image.path;
      // show panel in library update mode
      this.setPanel({ action: "update", resource: "image" });
    },
    async saveImage(isUpdate = false) {
      const _save = async () => {
        this.loading = true;
        try {
          // it appends the timestamp in order to force a new entity version (etag)
          this.imagePayload.description =
            this.imagePayload.description +
            (isUpdate
              ? "\n-updated at " +
              new Date()
                .toISOString()
                .replace(/\D/g, "")
                .substring(0, 14)
              : "");
          let response = await this.$store.dispatch(
            "synoptic/" + (isUpdate ? "updateImage" : "uploadImage"),
            this.imagePayload
          );
          this.toggleLibrary();
          this.imagePayload = {
            description: "",
            images_library_id: null,
            path: null
          };
          this.validateSaveResponse(response);
          this.selectedImage = response;
          if (this.uploadingImage && !(response.path in this.loadedImages)) {
            let img = new Image();
            let path = response.path;
            img.onload = () => {
              this.setLoadedImage(img, path);
            };
            img.src = this.uploadingImage;
          }
        } catch (error) {
          this.alert = {
            type: "error",
            title: this.$t("an_error_has_occurred"),
            text: error.message || this.$t("unknown_error")
          };
          //console.error(error);
        } finally {
          this.loading = false;
          this.showAlert();
          if (this.selectedImage) {
            this.$nextTick(() => {
              setTimeout(
                () => {
                  console.log(`anc${this.selectedImage.id}`)
                  let el = document.getElementById(`anc${this.selectedImage.id}`)
                  if (el)
                    el.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'end' })
                },
                500,
                this
              );
            });
          }
        }
      };
      if (isUpdate) {
        const folder =
          (
            this?.imagePayload?.images_library_id &&
            (this.imagesLibraries || []).find(
              ({ id }) =>
                parseInt(id) == parseInt(this.imagePayload.images_library_id)
            )
          )?.name || "untitled";
        this.$utils
          .confirm(
            this,
            this.$t("hints.overwrite_existing_file", {
              file: this?.imagePayload?.path?.name || "unamed",
              folder: folder
            }),
            "titles.overwrite_existing_file"
          )
          .then((confirmed) => {
            if (confirmed) {
              _save();
            }
          });
      } else {
        _save();
      }
    },
    async removeImage(image) {
      let confirm = await this.$swal({
        title: this.$t("are_you_sure"),
        content: this.warningContent(
          "image",
          image.name,
          "you_wont_be_able_to_revert_this"
        ),
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("yes_delete_it")]
      });

      if (confirm) {
        try {
          this.loading = true;
          await this.$store.dispatch("synoptic/removeImage", image.id);
          if (image.path == this.src) {
            // if it is the same image reset the control
            this.reset();
          } else {
            // another image was removed, emit so an event that would allow the parent to reset it on another control (image list)
            this.$emit("imageRemoved", image.path);
          }
          this.alert = {
            title: this.$t("delete"),
            text: this.$t("you_have_deleted_n_items", { count: 1 }),
            type: "success"
          };
        } catch (error) {
          this.alert = {
            type: "error",
            title: this.$t("an_error_has_occurred"),
            text: error.message || this.$t("unknown_error")
          };
          //console.error(error);
        } finally {
          this.loading = false;
          this.showAlert();
        }
      }
      this.clearSelected();
    },
    createLibrary() {
      this.libraryPayload = {
        name: "",
        description: "",
        public: false
      };
      this.setPanel({ action: "create", resource: "library" });
    },
    editLibrary() {
      let library = this.imagesLibraries.find(
        ({ id }) => id == this.filterImagesBy
      );
      this.libraryPayload = {
        id: library.id,
        etag: library.etag,
        name: library.name,
        description: library.description,
        contract_id: library.contract_id,
        public: library.public
      };
      this.setPanel({ action: "update", resource: "library" });
    },
    async saveLibrary(isUpdate = false) {
      this.loading = true;
      this.libraryPayload.contract_id = this.$store.getters["user/contract_id"];
      try {
        let response = await this.$store.dispatch(
          "synoptic/" +
          (isUpdate ? "updateImagesLibrary" : "createImagesLibrary"),
          this.libraryPayload
        );
        this.toggleLibrary();
        this.libraryPayload = {
          name: "",
          description: "",
          contract_id: null,
          public: false
        };
        this.validateSaveResponse(response);
      } catch (error) {
        this.alert = {
          type: "error",
          title: this.$t("an_error_has_occurred"),
          text: error.message || this.$t("unknown_error")
        };
        //console.error(error);
      } finally {
        this.loading = false;
        this.showAlert();
      }
    },
    async removeLibrary() {
      let imgLibrary = this.imagesLibraries.find(
        ({ id }) => id == this.filterImagesBy
      );
      let confirm = await this.$swal({
        title: this.$t("are_you_sure"),
        content: this.warningContent(
          "library",
          imgLibrary.name,
          "you_wont_be_able_to_revert_this"
        ),
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("yes_delete_it")]
      });

      if (confirm) {
        try {
          this.loading = true;
          this.removing = true;
          await this.$store.dispatch(
            "synoptic/removeImagesLibrary",
            imgLibrary.id
          );
          this.alert = {
            title: this.$t("delete"),
            text: this.$t("you_have_deleted_n_items", { count: 1 }),
            type: "success"
          };
          this.toggleLibrary();
          // update images list
          this.fetchImages()
            .catch((e) => {
              //console.log(e);
              this.noImages = true;
            }) // stop loading only after update is complete
            .then(() => (this.loading = false));
          // set filter to all images
          this.filterImagesBy = 0;
        } catch (error) {
          this.alert = {
            type: "error",
            title: this.$t("an_error_has_occurred"),
            text: error.message || this.$t("unknown_error")
          };
          //console.error(error);
          this.loading = false;
        } finally {
          this.showAlert();
          this.removing = false;
        }
      }
    },
    calculateParentOffset() {
      // let floatPanelElem = this.$refs?.floatPanel?.$el;
      // if (!floatPanelElem) {
      //   this.parentOffset = 0;
      //   return;
      // }
      // // let floatPanelHeight = parseInt(
      // //     window.getComputedStyle(floatPanelElem).height
      // //   ),
      // //   offsetTop = this.$el.getBoundingClientRect().y;
      // let floatPanelHeight = floatPanelElem.getBoundingClientRect().height;
      // let offsetTop = this.$el.getBoundingClientRect().y;
      // this.parentOffset =
      //   floatPanelHeight > offsetTop - 50
      //     ? floatPanelHeight - offsetTop + 50
      //     : 0;
    },
    setLoadedImage(img, path) {
      this.$set(this.loadedImages, path, {
        desc: `<span class="img-size">${img.naturalWidth}x${img.naturalHeight}</span>`,
        width: img.naturalWidth,
        height: img.naturalHeight,
        ratio: ((img.naturalHeight || 1) / (img.naturalWidth || 1)).toFixed(1)
      });
    },
    setImageLoaded(path) {
      let img = this.$refs.images.find((img) =>
        img.src.startsWith(path.toString())
      );
      this.setLoadedImage(img, path);
      this.$nextTick(() =>
        $(img)
          .closest(".thumbnail")
          .tooltip({
            placement: "auto bottom",
            delay: { show: 600, hide: 100 },
            html: true
          })
      );
    },
    isLoadedImage(path) {
      return this.loadedImages[path];
    },
    fetchImages() {
      return this.$store.dispatch("synoptic/fetchImages");
    },
    fetchImagesLibraries() {
      return this.$store.dispatch("synoptic/fetchImagesLibraries");
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    }
  },
  mounted() {
    if (!this.imagesLibraries)
      this.fetchImagesLibraries().catch((e) => {
        //console.log(e);
        this.noImagesLibraries = true;
      });
    if (!this.images)
      this.fetchImages().catch((e) => {
        //console.log(e);
        this.noImages = true;
      });
    this.setCurrentImageSize();
  }
};
</script>

<style lang="scss" scoped>
.float-panel {
  padding: 30px 0px 0px 0px;
}

.float-panel:hover {
  cursor: move;
}

.float-panel > div.header {
  position: absolute;
  top: 10px;
  left: 10px;
}
.popup {
  width: 600px;
  height: 500px;
  padding: 0 10px;
  position: relative;
  resize: both;
  overflow: hidden auto;
}

.popup > .popup-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.images {
  height: calc(100% - 115px);
  width: 100%;
  overflow: hidden auto;
  padding: 1rem 1rem 0 1rem;

  .thumbnail {
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: 100%;
    height: 100%;

    img {
      min-width: 60px;
    }

    .tooltip {
      font-size: 1.15rem;

      .tooltip-arrow,
      .tooltip-inner {
        background-color: #424141;
      }

      .img-size {
        color: #b2d0d8;
        font-style: italic;
      }
    }

    span:not([class]) {
      width: 60px;
      display: inline-block;
      font-size: 0.9em;
      line-height: 1em;
      margin-top: 0.3em;
      overflow-wrap: break-word;
    }

    img,
    span:not([class]) {
      transition: all 0.4s ease-in-out;
      opacity: 0;
      visibility: hidden;
    }

    &.image-loaded {
      width: auto;
      height: auto;
      scroll-behavior: smooth; /* <--- */

      img,
      span:not([class]) {
        visibility: visible;
        opacity: 1;
      }

      .spinner {
        display: none;
      }
    }

    &.selected {
      outline: 2px auto #367fa9;
    }

    &:focus:not(.selected) {
      outline: none;
    }

    .download {
      position: absolute;
      top: -0.8em;
      left: -0.8em;
      font-size: 1rem;
      padding: 0.4rem 0.6rem;
    }

    .delete {
      position: absolute;
      top: -0.8em;
      right: -0.8em;
      font-size: 1rem;
      padding: 0.4rem 0.6rem;
    }

    .edit {
      position: absolute;
      top: -0.8em;
      right: 1.8em;
      font-size: 1rem;
      padding: 0.4rem 0.6rem;
    }
  }
}

.libraries {
  padding: 1rem 1rem 0 1rem;
  width: 100%;

  label {
    margin-right: 1rem;
  }

  .input-group-btn .btn {
    height: 28px;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 1.1rem;
  }

  .input-group .form-control {
    height: 28px;
  }
}

.search {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  height: 0;
  transition: 200ms;

  &.active {
    height: 30px;
    transition: 200ms;
  }

  .input-group {
    z-index: 0;
  }
}

#image-preview {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-height: 4rem;
  margin-bottom: 1.5rem;

  img {
    height: 3rem;
  }
}

.form-inline {
  margin-top: 1rem;
}

#img-libraries {
  padding-left: 0.3rem;
}

.action-buttons {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  margin-top: 1em;

  & > .btn {
    flex: 1;

    &:not(:last-child) {
      margin-right: 0.6rem;
    }
  }
}

#imageFile {
  display: none;
}
.input-url {
  font-size: 9pt !important;
  padding: 0 3px !important;
}
</style>
<style>
.libraries .btn-group .btn {
  height: 28px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 1.1rem;
}

.thumbnail.image-loaded + .tooltip .tooltip-inner {
  background-color: #424141;
}
.thumbnail.image-loaded + .tooltip .tooltip-arrow {
  border-bottom-color: #424141;
}

.thumbnail.image-loaded + .tooltip .img-size {
  color: #b2d0d8;
  font-style: italic;
}
</style>
