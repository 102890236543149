<script>
import CustomerPageBanner from "@/components/customer-page-banner.vue";
import EquipmentSearchPanel from "@/components/equipment-search-panel.vue";
export default {
  name: "EquipmentSearchBase",
  components: {
    CustomerPageBanner,
    EquipmentSearchPanel
  },
  data: function() {
    return {
      isReady: false,
      selectedEquipment: null,
      search: true,
      nItems: {
        available: 0,
        filtered: 0
      },
      refreshEnabled: false,
      sortOrder: null
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    contract() {
      return this.$store.getters["user/contract"];
    },
    contract_id() {
      return this?.contract?.id || 0;
    },
    source() {
      let self = this;
      let cfg =
        (self.$root.config && self.$root.config.equipment_selection) || null;
      let source = (cfg && cfg.source) || "device";
      let lst = ((cfg && cfg.tabs && cfg.tabs.items) || []).filter(function(i) {
        return i.component == self.$options.name && i.source;
      });
      source = lst.length ? lst[0].source : source;
      return source;
    },
    isConfigurationReady() {
      var lst = this.equipmentList;
      return (lst || []).length > 0;
    },
    equipmentList() {
      return this.$store.getters["equipment/filteredList"];
    },
    currentPage() {
      return this.$store.getters["currentPage"];
    },
    nContractConnectors() {
      return this.contract ? this.contract?.registered_connectors : 0;
    },
    nConnectors() {
      // usable connector instances (does not consider any model)
      return this.contract
        ? (
          (this.equipmentList || []).filter(
            ({ base_model }) => !base_model
          ) || []
        ).length
        : 0;
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    enabledConnectorList() {
      return this.$utils.distinct(this.$store.getters["equipment/enabledItems"] || [])
    },
    mqttConnectorTopics() {
      let topics = null;
      (this.enabledConnectorList || [])
        .filter(
          ({ base_model, mqtt_topic_prefix }) =>
            !base_model && mqtt_topic_prefix
        )
        .forEach(({ id, mqtt_topic_prefix }) => {
          topics = topics || {};
          topics[mqtt_topic_prefix] = id;
        });
      return topics;
    }
  },
  watch: {
    source: {
      handler(n) {
        this.$store.dispatch("equipment/setSource", n); // connector or device
      },
      immediate: true
    },
    refreshEnabled: {
      handler(n, o) {
        let self = this;
        if (n) {
          if (!self._refreshTimer) {
            if (this.$store.getters.brokerStatus === 'CONNECTED') return;
            // begin - test only
            //self?.$root?.config?.dashboard?.refreshInterval=5000;
            // end
            let interval =
              self?.$root?.config?.dashboard?.refreshInterval || 30000;
            self._refreshTimer = setInterval(() => {
              if (self._refreshTimer) {
                self.updateCurrentPage();
                // validate interval changes
                if (
                  interval !=
                  (self?.$root?.config?.dashboard?.refreshInterval || 30000)
                ) {
                  self.refreshEnabled = false;
                  self.$nextTick(() => {
                    self.refreshEnabled = true; // force new config reload
                  });
                }
              }
            }, interval);
          }
        } else {
          if (self._refreshTimer) {
            clearTimeout(self._refreshTimer);
            self._refreshTimer = null;
          }
        }
      },
      immediate: true
    },
    equipmentList: {
      handler() {
        let stats = this.$store.getters["equipment/stats"];
        this.$emit("nItemsChanged", {
          scope: this.scope,
          available: stats.available,
          filtered: stats.source.filtered
        });
      },
      immediate: true
    },
    isLoading: {
      handler(n, o) {
        // this.$emit("loading", n);
        if (o && !n) {
          this.$emit("finished", this.equipmentList.length);
          this.isReady = true;
        }
      },
      immediate: true
    },
    nConnectors: {
      handler(n, o) {
        if (!o && n) {
          this.isReady = true;
          if (this.mqttConnectorTopics) {
            this.setupProtocol();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    showEquipmentWithId(value) {
      var equipment = this.$store.getters.equipmentById(value);
      if (equipment) {
        this.$router.push("/dashboard/equipment/" + value);
      }
    },
    updateCurrentPage() {
      this.$root.$emit("refreshPage");
    },
    fetchScreens(ids) {
      let nScreens = this.screens.length;
      if (
        !nScreens ||
        nScreens == (this.$root?.config?.screens || []).length ||
        ((ids || []).length &&
          this.screens.filter(({ id }) => (ids || []).indexOf(id) >= 0)
            .length != (ids || []).length)
      ) {
        let query = {
          contract_id: this.contract_id
        };
        if ((ids || []).length) {
          query.ids = ids.join(",");
        }
        this.$store.dispatch("dashboard/fetchScreens", query);
      }
    },
    onMQTTStatus(status) {
      if (status === 'CONNECTED') {
        this.refreshEnabled = false;
        this.$http.options?.mqtt.subscribeOnlyOnTopics(
          Object.keys(this.mqttConnectorTopics || {}).map((t) => `${t}/#`)
        );
      }
    },
    onMQTTMessage($event) {
      let entry;
      let topic = $event.info.destinationName.replace(
        /\/(connector_state|completed_data_acquisition)/g,
        ""
      );
      let connector = this.enabledConnectorList.find(
        ({ mqtt_topic_prefix }) => mqtt_topic_prefix == topic
      );
      if (!connector) return;
      if (/connector_state/.test($event.info.destinationName)) {
        if (connector) {
          entry = {
            id: connector.id,
            ...$event.msg
          };
          this.$store.dispatch("dashboard/setConnectorValue", entry);
          // if ($event.msg.connected && !connector.is_connected) {
          //   this.$nextTick(this.updateCurrentPage);
          // }
        }
      } else if (/completed_data_acquisition/.test($event.info.destinationName)) {
        // it is only valid for modbus connectors
        if (
          this._dataRefreshTimer ||
          !connector ||
          connector?.protocol?.is_mqtt_protocol
        )
          return;
        // it skips connection status - since it will be handle by a different topic
        // console.log("completed_data_acquisition");
        if (connector?.devices?.length && connector.devices.filter(({ is_connected }) => !is_connected).length == connector.devices.length) {
          this.$nextTick(this.updateCurrentPage);
        }
      }
    },
    setupProtocol() {
      if (
        this._mqttObserver ||
        !this?.$http.options.mqtt ||
        !this?.mqttConnectorTopics ||
        this.dashboardMode == "editor" ||
        this.mode == "editor"
      )
        return;
      this._mqttObserver = true;
      this.onMQTTStatus(this.$http.options.mqtt.status);
      return;
    }
  },
  beforeCreate() {
    this._onMQTTStatus = ($e) => this.onMQTTStatus && this.onMQTTStatus($e.detail);
    this._onMQTTMessage = ($e) => this.onMQTTMessage && this.onMQTTMessage($e.detail);
    document.addEventListener("mqtt:status", this._onMQTTStatus);
    document.addEventListener("mqtt:message", this._onMQTTMessage);
  },
  created() {
    this._refreshTimer = null;
    if (!this.nConnectors || this.nContractConnectors >= this.nConnectors) {
      this.fetchScreens();
      this.$emit("fetchEquipmentList");
    } else if (this.nConnectors) {
      this.$nextTick(() => {
        this.updateCurrentPage();
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener("mqtt:status", this._onMQTTStatus);
    document.removeEventListener("mqtt:message", this._onMQTTMessage);
    let self = this;
    this.refreshEnabled = false;
    if (self._refreshTimer) {
      clearInterval(self._refreshTimer);
      self._refreshTimer = null;
    }
  },
  mounted() {
    let self = this;
    self.$nextTick(function() {
      $('a[data-toggle="tab"]').on("shown.bs.tab", function(evt) {
        self.search = !(evt.target.href.indexOf("equipment-map") > 0);
      });
    });
    this.$root.$emit("controlSidebar:setContent", null);
  }
};
</script>

<style scoped>
.tab-search {
  padding-bottom: 200px !important;
}

.equipment-item:hover {
  cursor: pointer;
}
.equipment-item .info-box {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.equipment-item .info-box:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.equipment-enabled {
  opacity: 1;
}
</style>
