<template>
  <DetailForm persistent="toggle_card_panel">
    <DetailFormConnectorCards />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormConnectorCards from "@/components/control-sidebar/property-editors/detail-form-connector-cards.vue";
export default {
  name: "EquipmentCardPanelForm",
  components: {
    DetailForm,
    DetailFormConnectorCards
  }
};
</script>
