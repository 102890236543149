<template>
  <section
    class="selection-container"
    :class="mode == 'editor' ? 'editor' : ''"
  >
    <div
      :class="{
        'editor-toolbar': mode == 'editor',
        editing: isEditing
      }"
    >
      <slot name="toolbar"></slot>
    </div>
    <div class="container-content">
      <div class="row">
        <div
          class="col-xs-12 col-md-6 col-lg-4"
          v-for="(item, index) in items"
          v-bind:key="index"
        >
          <GenericCard
            :entry="item"
            :config="panelOptions.card"
            @pick="pick"
          ></GenericCard>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Panels from "@/assets/dashboard/panels.json";
import GenericCard from "@/components/generic-card.vue";
import EquipmentCardPanelForm from "@/components/control-sidebar/property-editors/equipment-card-panel-form.vue";

export default {
  name: "EquipmentCardPanel",
  props: {
    equipmentList: {
      type: Array,
      required: false,
      defult: () => []
    },
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    GenericCard
  },
  computed: {
    panelOptions() {
      // TODO: get it from the configuration
      return (
        this?.panel?.options ||
        Panels.find((panel) => panel.template.template == this.$options.name)
          .template.options
      );
    },
    items() {
      return this.equipmentList || this.equipmentStoreList;
    },
    equipmentStoreList() {
      return (this.$store.getters.equipmentList || []).filter(
        (i) => !i.base_model && i.enabled
      );
    },
    panelStyle() {
      let style = JSON.parse(JSON.stringify(this?.panel?.options?.style || {}));
      return style;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    }
  },
  watch: {
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "EquipmentCardPanelForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: EquipmentCardPanelForm
            });
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    pick: function(value) {
      this.$emit("pick", value);
    },
    onEdit() {
      if (this.mode != "editor") return;
      this.$emit("initCustomProperties", {
        panelName: this.panel.name,
        propertyEditor: EquipmentCardPanelForm
      });
      this.setSideBar();
    },
    setSideBar() {
      if (this.sidebar.name != "EquipmentCardPanelForm") {
        this.$root.$emit("controlSidebar:setContent", EquipmentCardPanelForm);
      }
    }
  }
};
</script>

<style scoped>
.editor-toolbar {
  position: absolute;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editor-toolbar.editing {
  border-top-color: #f39c12;
}

.selection-container {
  padding: 20px 5px;
  background-color: whitesmoke;
}

.container-content {
  clear: both;
}

.selection-container.editor {
  padding: 0 5px;
}
.selection-container.editor > .container-content {
  margin-top: 25px;
}
</style>
