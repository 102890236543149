<template>
  <div
    :class="['float-panel', { active: open }]"
    :style="[currentStyle, draggingStyle]"
  >
    <slot></slot>
    <button
      type="button"
      class="close"
      aria-label="Close"
      @click="$emit('update:open', false)"
      data-testid="close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import { whichTransitionEvent, clampDownPosition } from "@/utils";

export default {
  name: "FloatPanel",
  props: {
    open: {
      type: Boolean,
      required: true
    },
    defaultPosition: {
      type: Object,
      required: false,
      default: () => ({})
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    },
    handle: {
      type: String,
      required: false,
      default: ""
    },
    handleSelf: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      dragAndDropStyle: {},
      dragging: false,
      initialAbsPos: {},
      currentPosition: null
    };
  },
  computed: {
    baseStyle() {
      return {
        ...this.defaultPosition
      };
    },
    currentStyle() {
      return this.currentPosition ? this.dragAndDropStyle : this.baseStyle;
    },
    draggingStyle() {
      return this.dragging
        ? { cursor: "grabbing", "user-select": "none" }
        : { transition: "200ms" };
    }
  },
  watch: {
    open(newVal, oldVal) {
      if (!oldVal && newVal) {
        this._event = "open";
      } else if (oldVal && !newVal) {
        this._event = "close";
      }
    },
    defaultPosition: {
      deep: true,
      handler(val) {
        // overrides current position (if dragged)
        // when defaultPosition updates
        if (!this.currentPosition) return;

        if (val?.top != undefined)
          this.currentPosition.top = this.dragAndDropStyle.top = val.top;
        if (val?.right != undefined)
          this.currentPosition.right = this.dragAndDropStyle.right = val.right;
        if (val?.bottom != undefined)
          this.currentPosition.bottom = this.dragAndDropStyle.bottom =
            val.bottom;
        if (val?.left != undefined)
          this.currentPosition.left = this.dragAndDropStyle.left = val.left;
      }
    }
  },
  methods: {
    setDragEvents(on = true) {
      let methodName = on ? "addEventListener" : "removeEventListener";
      this.$el[methodName]("mousedown", this.onMousedown, true);
      document.documentElement[methodName]("mouseup", this.onMouseup, true);
      document.documentElement[methodName]("mousemove", this.onMousemove, true);
    },
    emitOpenCloseEvent(e) {
      if (this._event) {
        this.$emit(this._event);
        this.$emit("toggle", this._event == "open" ? true : false);
        if (this._event == "open") {
          this.$emit(
            "update:defaultPosition",
            clampDownPosition(this.$el, this.defaultPosition)
          );
        }
        this._event = null;
      }
    },
    onMousedown(e) {
      if (
        (this.handle &&
          (e.target.matches?.(this.handle) ||
            e.target.matchesSelector?.(this.handle))) ||
        (this.handleSelf && e.target == this.$el)
      ) {
        this.dragging = true;
        this.initialAbsPos = { x: e.clientX, y: e.clientY };
        if (!this.currentPosition) {
          this.currentPosition = {
            top: 0,
            left: 0,
            ...this.defaultPosition
          };
          this.dragAndDropStyle = { ...this.defaultPosition };
        }
        this.$emit("dragstart", { ...this.initialAbsPos });
      }
    },
    onMouseup(e) {
      if (this.dragging) {
        this.dragging = false;
        this.dragAndDropStyle = clampDownPosition(this.$el, {
          ...this.dragAndDropStyle
        });
        this.currentPosition = { ...this.dragAndDropStyle };
        this.$emit("update:defaultPosition", { ...this.currentPosition });
        this.$emit("dragend", { ...this.currentPosition });
      }
    },
    onMousemove(e) {
      if (this.dragging) {
        let offsetY = e.clientY - this.initialAbsPos.y;
        let offsetX = e.clientX - this.initialAbsPos.x;

        if (this.currentPosition.bottom != undefined) {
          this.dragAndDropStyle.bottom =
            parseInt(this.currentPosition.bottom) - offsetY + "px";
        } else {
          this.dragAndDropStyle.top =
            parseInt(this.currentPosition.top) + offsetY + "px";
        }

        if (this.currentPosition.right != undefined) {
          this.dragAndDropStyle.right =
            parseInt(this.currentPosition.right) - offsetX + "px";
        } else {
          this.dragAndDropStyle.left =
            parseInt(this.currentPosition.left) + offsetX + "px";
        }
        this.$emit("drag", { ...this.dragAndDropStyle });
      }
    }
  },
  mounted() {
    if (this.draggable && (this.handle || this.handleSelf)) {
      this.setDragEvents();
    }
    this.$el.addEventListener(
      whichTransitionEvent(),
      this.emitOpenCloseEvent.bind(this)
    );
  },
  beforeDestroy() {
    this.setDragEvents(false);
  }
};
</script>

<style lang="scss" scoped>
.float-panel {
  position: absolute;
  box-shadow: 0 0 6px 2px rgba(165, 165, 165, 0.733);
  background: white;
  z-index: 1100;
  border-radius: 5px;
  transform: scale(0);
  transform-origin: bottom right;

  &.active {
    transform: scale(1);
  }

  .close {
    top: 0px;
    right: 4px;
    position: absolute;
  }
}
</style>
