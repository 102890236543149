<template>
  <div class="overlay text-center">
    <i class="fa fa-refresh fa-spin fa-4x"></i>
  </div>
</template>

<script>
export default {
  name: "Spin"
};
</script>

<style scoped>
.overlay {
  opacity: 0.5;
}
.overlay > i {
  color: #666;
}
</style>
