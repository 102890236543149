var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"me"},[_c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"active",attrs:{"title":_vm.$t('properties')}},[_c('a',{attrs:{"href":"#panel-detail-editor","data-toggle":"tab","aria-expanded":"true"}},[_c('i',{staticClass:"fa fa-panel"}),_c('span',[_vm._v(_vm._s(_vm.$tc("panel", 1)))])])]),_c('li',{staticClass:"disabled",attrs:{"title":_vm.$t('controls')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}}),_c('li',{staticClass:"disabled",attrs:{"title":_vm.$t('events')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}})]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane active",attrs:{"id":"panel-detail-editor"}},[_c('PanelTypeTitle',{attrs:{"title":_vm.originalTitle},on:{"edit":_vm.editGeneralPanelProperties}}),(_vm.canEditProperties)?[(_vm.persistent)?_c('TogglePanel',{attrs:{"title":_vm.$t('specific_properties'),"icon":{
            before: 'fa fa-sliders',
            collapse: 'fa-minus',
            expand: 'fa-plus'
          },"persistent":_vm.persistent}},[_vm._t("default")],2):_vm._t("default")]:[(_vm.persistent)?_c('TogglePanel',{attrs:{"title":_vm.$t('specific_properties'),"icon":{
            before: 'fa fa-sliders',
            collapse: 'fa-minus',
            expand: 'fa-plus'
          },"persistent":_vm.persistent}},[(_vm.isSyncEnabled)?_c('LinkedPanelWarning'):_vm._e()],1):_vm._e()]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }