<template>
  <div>
    <div v-if="floatPanel.open">
      <input class="form-control" :value="iValue" disabled />
      <portal to="contextmenu">
        <FloatPanel
          class="float-panel"
          :draggable="true"
          :handleSelf="true"
          :defaultPosition="{ top: floatPanel.top, left: floatPanel.left }"
          :open="floatPanel.open"
          @update:open="setPicker($event)"
          @dragstart="floatPanel.dragging = true"
          @dragend="onDragEnd(floatPanel, $event)"
        >
          <div class="popup">
            <div
              class="header clicable"
              :title="$t('expand_collapse_panel') + ' Editor'"
              @click.stop.prevent="setPicker(true, !picker)"
            >
              <i
                class="fa"
                :class="
                  jseditor.enabled
                    ? 'fa-file-code-o'
                    : picker
                    ? 'fa-code'
                    : 'fa-th-list'
                "
              />
              <i
                v-if="!jseditor.enabled && !picker"
                class="fa fa-code"
                style="margin-left:5px"
              />
              {{
                $t(jseditor.enabled ? "javascript_editor" : "expression_editor")
              }}
            </div>
            <div class="popup-body" v-if="jseditor.enabled">
              <JSEditor
                class="js-editor"
                :enviroment="JSEnviroment()"
                v-model="iValue"
                @ready="jseditor.ready = true"
              />
              <span class="js-loading" v-if="!jseditor.ready">
                <i class="fa fa-refresh fa-spin"></i>
                <span>{{ $t("loading") }}</span>
              </span>
              <span class="js-editor-toolbar ">
                <span
                  class="clicable"
                  @click.stop.prevent="toggleJSEditor()"
                  :title="$t('expression_editor')"
                >
                  <i class="fa fa-code"></i>
                </span>
              </span>
            </div>
            <div class="popup-body" v-else>
              <span class="js-editor-toolbar ">
                <span
                  class="clicable"
                  @click.stop.prevent="toggleJSEditor()"
                  :title="$t('javascript_editor')"
                >
                  <i class="fa fa-file-code-o"></i>
                </span>
              </span>
              <pre id="json-renderer" ref="out" v-if="picker && entry"></pre>
              <div>
                <textarea
                  class="form-control"
                  v-model="iValue"
                  ref="inp"
                  style="height:100%"
                  :placeholder="$t('expression')"
                ></textarea>

                <div class="toolbar">
                  <template v-if="iValue">
                    <span class="clicable" @click.stop.prevent="iValue = ''">
                      <i class="fa fa-close"></i>
                    </span>
                  </template>
                  <template v-else-if="trashCan">
                    <span class="clicable" @click.stop.prevent="restore()">
                      <i class="fa fa-undo"></i>
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </FloatPanel>
      </portal>
    </div>
    <div v-else class="me">
      <div class="expression">
        <textarea
          class="form-control"
          v-model="iValue"
          ref="inp"
          @click.ctrl.exact="setPicker(true, true)"
        ></textarea>
        <div class="toolbar">
          <template v-if="iValue">
            <span class="clicable" @click.stop.prevent="iValue = ''">
              <i class="fa fa-close"></i>
            </span>
          </template>
          <template v-else-if="trashCan">
            <span class="clicable" @click.stop.prevent="restore()">
              <i class="fa fa-undo"></i>
            </span>
          </template>
          <template v-if="popUpOnly">
            <span
              v-if="entry"
              class=" clicable"
              @click.exact="setPicker(true, true)"
              :title="$t('hints.click_to_open_ctrl_click_to_popup_it')"
            >
              <i class="fa fa-pencil"></i>
            </span>
          </template>
          <template v-else>
            <span
              v-if="entry"
              class=" clicable"
              @click.exact="setPicker()"
              :title="$t('hints.click_to_open_ctrl_click_to_popup_it')"
            >
              <i class="fa fa-chevron-up" v-if="picker"></i>
              <i class="fa fa-chevron-down" v-else></i>
            </span>
          </template>
        </div>
      </div>
      <pre id="json-renderer" ref="out" v-if="picker && entry"></pre>
    </div>
  </div>
</template>

<script>
import FloatPanel from "@/components/editor/float-panel.vue";
import JSEditor from "@/components/editor/js-script-editor/js-editor.js";
import { loadMonaco } from "@/components/editor/js-script-editor/js-editor.js";
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import {
  jsonPathPicker,
  clearJsonPathPicker
} from "@/utils/jsonpath-picker.js";

// initial float panel position
const _fpsize = [500, 200];
let _fp = {
  h: _fpsize[1],
  w: _fpsize[0],
  y: parseInt((window.innerHeight - _fpsize[1]) / 2) - 150,
  x: parseInt((window.innerWidth - _fpsize[0]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);

const JSEnviroment = {
  template: ["(()=>{\n    return `", "_value_", "`\n})()"],
  // input translation
  iconv(vm, value) {
    if (value === "") return value;
    // remove pipe operation
    let v = value.replace(/\|\|/g, "§").split("|"); // preserve ||
    let vlr = vm.$utils
      .trim(v[0].replace(/§/g, "||"))
      .trimStart()
      .trimEnd();
    // let vlr = (value || "")
    //   .split("|")[0]
    //   .trimStart()
    //   .trimEnd();
    if (vlr.indexOf("function") >= 0 || vlr.indexOf("=>") >= 2) {
      if (/^\$\{.*\}$/.test(vlr)) {
        vlr = vlr.replace(/^\$\{/, "").replace(/\}$/, "");
      }
    } else {
      vlr = this.template.join("").replace(/(_value_)/, vlr);
    }
    return vlr.trimStart().trimEnd();
  },
  // output translation
  oconv(vm, newValue, oldValue) {
    if (newValue === "") return newValue;
    let v = newValue;
    let s = this.template[0];
    let e = this.template[2];
    if (v.indexOf(s) >= 0 && v.indexOf(e) >= 0) {
      v = v.replace(s, "").replace(e, "");
    } else {
      v = `\$\{${newValue}\}`;
    }
    // inject removed pipe operation
    let piped =
      oldValue
        .replace(/\|\|/g, "§")
        .trimStart()
        .trimEnd()
        .split("|")[1] || "";
    if (piped) {
      v = `${v}|${piped}`;
    }
    return v;
  },
  // this enviroment suported context
  context(vm) {
    return new Promise((resolve) => {
      let ctx = JSON.parse(JSON.stringify(SelectableObjects));
      let sys = JSON.parse(JSON.stringify(vm.$store.getters.systemProperties));
      ctx.data.history = { ...ctx.history, ...(sys?.history || {}) };
      ctx.data.device = ctx.device;
      ctx.data.device.connector = ctx.connector;
      ctx.data.user = sys?.user || {};
      ctx.data.broker = sys?.broker || {};
      resolve(ctx);
    });
  }
};

export default {
  name: "JSONPathPicker",
  props: {
    entry: {
      type: Object,
      required: false,
      default: () => null
    },
    entryType: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    append: {
      // true=keep previous picked node
      type: Boolean,
      required: false,
      default: true
    },
    curlyBracket: {
      // true=add property sunrounded by curlyBracket
      type: Boolean,
      required: false,
      default: true
    },
    popUpOnly: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    FloatPanel,
    JSEditor
  },
  data() {
    return {
      picker: false,
      jseditor: {
        enabled: false,
        ready: false
      },
      floatPanel: {
        top: `${_fp.y}px`,
        left: `${_fp.x}px`,
        open: false,
        dragging: false
      },
      trashCan: ""
    };
  },
  watch: {
    entry: {
      handler(n) {
        if (n) {
          if (!this.picker) return;
          this.picker = false;
          this.$nextTick(() => {
            this.picker = true;
          });
        }
      },
      deep: true
    },
    picker(n) {
      if (n) {
        this.$nextTick(() => {
          if (this.$refs.out) {
            let r = this.$parent.$el.getBoundingClientRect();
            this.floatPanel.top = `${window.scrollY + r.top}px`;
            this.showPicker();
          } else {
            this.$nextTick(() => {
              this.showPicker();
            });
          }
        });
      }
    }
  },
  computed: {
    iValue: {
      set(vlr) {
        if (this.iValue && !vlr) {
          this.trashCan = this.iValue;
        }
        this.$emit("input", vlr);
        if (!vlr) {
          this.focus();
        }
      },
      get() {
        return this.value || "";
      }
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        if (this.$refs.inp) this.$refs.inp.focus();
      });
    },
    setPicker(floatPanel, picker) {
      if (floatPanel != undefined) {
        this.floatPanel.open = floatPanel;
      }
      if (picker === undefined) {
        this.picker = !this.picker;
      } else {
        if (picker == this.picker) {
          this.picker = !this.picker;
          this.$nextTick(() => {
            this.picker = picker;
          });
        } else {
          this.picker = picker;
        }
      }
    },
    showPicker() {
      let self = this;
      let pathPrefix = self.entryType ? `${self.entryType}.` : "";
      if (this.$refs.out && this.entry) {
        clearJsonPathPicker(this.$refs.out);
        jsonPathPicker(this.$refs.out, JSON.parse(JSON.stringify(this.entry)), {
          outputWithQuotes: false,
          outputCollapsed: true,
          pathNotation: "dots",
          pathQuotesType: "single",
          selected: function(path) {
            if (path) {
              let prop = pathPrefix + path;
              let txt = self.$refs.inp.value;
              if (self.append && txt.length) {
                let curPos = self.$refs.inp.selectionStart;
                let p1 = txt.slice(0, curPos);
                let p2 = txt.slice(curPos);
                if (
                  (self.curlyBracket &&
                    ((p1 || "").match(/\$\{/g) || []).length ==
                      ((p1 || "").match(/\}/g) || []).length) ||
                  ((p2 || "").match(/\$\{/g) || []).length ==
                    ((p2 || "").match(/\}/g) || []).length
                ) {
                  prop = `\$\{${prop}\}`;
                }
                prop = p1 + prop + p2;
              } else {
                if (self.curlyBracket) {
                  prop = `\$\{${prop}\}`;
                }
              }
              self.iValue = prop;
            }
          }
        });
      }
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    },
    restore() {
      this.iValue = this.trashCan;
      this.trashCan = "";
    },
    toggleJSEditor() {
      this.jseditor.ready = false;
      this.jseditor.enabled = !this.jseditor.enabled;
      if (!this.jseditor.enabled) {
        this.picker = !this.picker;
        this.$nextTick(() => {
          this.picker = !this.picker;
        });
      }
    },
    JSEnviroment: () => JSEnviroment
  },
  beforeDestroy() {
    if (this.$refs.out) {
      clearJsonPathPicker(this.$refs.out);
    }
  },
  beforeCreate() {
    loadMonaco();
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  max-height: 400px;
}

.me > pre {
  padding: 4px 0 0 14px;
  max-height: 330px;
  min-height: 25px;
  display: block;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border-color: #fff #ccc #ccc #ccc;
  border-width: 1px;
  border-radius: 0 0px 4px 4px;
  border-style: solid;
  margin-top: -1px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.expression {
  position: relative;
}

.expression > textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 32px;
  height: 32px;
  padding: 4px 10px 0 4px;
}

.form-group-sm textarea.form-control {
  height: 32px;
}

.expression > .toolbar {
  position: absolute;
  z-index: 3;
  top: 5px;
  right: 5px;
  color: #999;
  background-color: #ffffff8c;
}

.expression > .toolbar > span {
  margin-left: 10px;
}

.expression:hover > .toolbar > span {
  color: rgb(80, 117, 218);
  color: #2e6da4;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.float-panel {
  padding: 30px 10px 10px 10px;
  overflow: hidden;
}

.float-panel:hover {
  cursor: move;
}
.popup {
  position: relative;
}

.popup > div.header {
  position: absolute;
  top: -26px;
  color: #666;
  font-weight: 600;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.popup > .popup-body {
  display: flex;
  min-width: 500px;
  min-height: 200px;
  max-height: 60vh;
  background-color: #f5f5f5;
  border: 1px solid lightgray;
  resize: both;
  overflow: auto;
}

.popup > .popup-body > pre {
  padding: 4px 15px;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  resize: horizontal;
}

.popup > .popup-body > div {
  position: relative;
  flex-grow: 1;
}

.popup > .popup-body > div > div.toolbar {
  position: absolute;
  z-index: 3;
  top: 5px;
  right: 5px;
  color: #999;
  background-color: #ffffff8c;
}
</style>

<style>
/* Syntax highlighting for JSON objects */
ul.json-dict,
ol.json-array {
  list-style-type: none;
  margin: 0 0 0 1px;
  border-left: 1px dotted #ccc;
  padding-left: 1em;
}

.json-string {
  color: #0b7500;
}
.json-literal {
  color: #1a01cc;
  font-weight: bold;
}

/* Toggle button */
a.json-toggle {
  position: relative;
  color: inherit;
  text-decoration: none;
}
a.json-toggle:focus {
  outline: none;
}
a.json-toggle:before {
  color: #aaa;
  content: "\25BC"; /* down arrow */
  position: absolute;
  display: inline-block;
  width: 1em;
  left: -1em;
}
a.json-toggle.collapsed:before {
  content: "\25B6"; /* left arrow */
}

/* Collapsable placeholder links */
a.json-placeholder {
  color: #aaa;
  padding: 0 1em;
  text-decoration: none;
}
a.json-placeholder:hover {
  text-decoration: underline;
}

.pick-path:hover {
  cursor: pointer;
  color: orange;
}

.js-editor-toolbar {
  position: absolute;
  right: 30px;
  top: -25px;
  color: gray;
}

.js-loading {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  padding-top: 50px;
  color: gray;
}
.js-loading > i {
  margin: 0 10px;
}
</style>
