<template>
  <DetailForm persistent="toogle_equipment_search_panel">
    <DetailFormEquipmentSearch />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormEquipmentSearch from "@/components/control-sidebar/property-editors/detail-form-equipment-search.vue";
export default {
  name: "EquipmentSearchPanelForm",
  components: {
    DetailForm,
    DetailFormEquipmentSearch
  }
};
</script>
