var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-box",class:_vm.hasListeners ? 'clicable' : '',style:(_vm.cardStyle),on:{"click":function($event){return _vm.pick(_vm.entry.id)}}},[_c('span',{staticClass:"info-box-icon",style:(_vm.iconBoxStyle)},[_c('i',{ref:"icon",class:_vm.iconClass,style:(_vm.iconStyle)},[(_vm.iconLabel)?_c('div',{staticClass:"info-box-icon-label",style:(_vm.iconLabel.style),attrs:{"title":_vm.iconLabel.hint},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.iconLabel.onClick && _vm.iconLabel.onClick($event)}}},[_vm._v(" "+_vm._s(_vm.iconLabel.text)+" ")]):_vm._e()])]),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text",style:(_vm.titleStyle)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.titleContent)}})]),_c('span',{staticClass:"info-box-number",style:(_vm.bodyStyle)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.bodyContent)}})]),(_vm.progress && _vm.progress.enabled)?_c('div',{staticClass:"progress",style:({
        'background-color': _vm.progress.style['background-color'] || '#00000033'
      })},[_c('div',{staticClass:"progress-bar",style:({
          width: _vm.progressValue + '%',
          backgroundColor: _vm.progress.color || _vm.progress.style.color
        })})]):_c('div',{staticClass:"progress-group"},[_vm._m(0)]),_c('div',{staticClass:"text-small"},[_c('div',{staticClass:"card-footer text-small",style:(_vm.footerStyle),attrs:{"title":_vm.footerContent}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.footerContent)}})])]),(_vm.display && _vm.display.enabled)?_c('span',{staticClass:"big-display",style:(_vm.displayStyle),domProps:{"innerHTML":_vm._s(_vm.displayContent)}}):_vm._e()]),_vm._t("default")],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress progress-sm"},[_c('div',{staticClass:"progress-bar bg-gray",staticStyle:{"width":"100%"}})])
}]

export { render, staticRenderFns }