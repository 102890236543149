export default {
  inserted: (
    el,
    { value: { loader, loadedClass, onError, onLoad, rootElement } = {} }
  ) => {
    function loadImage() {
      const imageElement =
        el.nodeName === "IMG"
          ? el
          : Array.from(el.children).find((el) => el.nodeName === "IMG");
      if (imageElement) {
        imageElement.addEventListener("load", () => {
          if (typeof loadedClass == "string") {
            el.classList.add(loadedClass);
          }
          if (typeof onLoad == "function") {
            onLoad();
          }
        });
        if (typeof onError == "function")
          imageElement.addEventListener("error", onError);
        if (typeof loader == "function") {
          loader(imageElement.dataset.src)
            .then((src) => (imageElement.src = src))
            .catch((e) => (typeof onError == "function" ? onError(e) : null));
        } else {
          imageElement.src = imageElement.dataset.src;
        }
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      let root;
      switch (typeof rootElement) {
        case "string":
          root = document.querySelector(rootElement);
          break;
        case "function":
          root = rootElement();
          break;
        default:
          root = rootElement;
      }
      const options = {
        root,
        threshold: "0"
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }
    if (window["IntersectionObserver"]) {
      createObserver();
    } else {
      loadImage();
    }
  }
};
